import React from 'react'
import HeadLogo from '../Assets/Oolook_logo.png'
import { Link } from 'react-router-dom'
import logimg from '../Assets/Icons/login-img.png'
import axios from 'axios'
import { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Login() {

    const [details, setDetails] = useState({
        email_id: '',
        password: ''
    })

    const [Remember, setRemeber] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    const navigate = useNavigate();


    const handel = (e) => {
        e.preventDefault();
        const newdata = { ...details };
        newdata[e.target.id] = e.target.value;
        setDetails(newdata)
    }

    const Login = async () => {
        const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        setIsLoader(true);
        if (details.email_id == '') {
            toast('Enter Email Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoader(false);
        }
        else if (emailRegex.test(details.email_id) === false) {
            toast('Enter Valid Email Address', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoader(false);
        }
        else if (details.password == '') {
            toast('Enter Password', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setIsLoader(false);
        }
        else {

            await axios.post(`${process.env.REACT_APP_BACKEND_API}/user/login`, {
                "email_id": details.email_id,
                "password": details.password
            })
                .then((res) => {
                    if (Remember == true) {
                        setIsLoader(false);
                        localStorage.setItem("Email", details.email_id);
                        localStorage.setItem("user_Token", res.data.data.token);

                        if (res.data.data.user.status == "created") {
                            navigate('/Onboarding')
                        }
                        else
                            if (res.data.data.user.status == "onboarding completed") {
                                console.log(res.data.data.user.status)
                                navigate('/Organization')
                            }
                            else if (res.data.data.user.status == "organization completed") {
                                navigate("/AssetsData")
                            }
                            else
                                if (res.data.data.user.status == "assets completed") {
                                    navigate('/ProfileConnect')
                                }
                                else {
                                    window.location.reload('/Dashboard')
                                }
                    }
                    else {
                        setIsLoader(false);
                        sessionStorage.setItem("Email", details.email_id)
                        sessionStorage.setItem("user_Token", res.data.data.token)
                        if (res.data.data.user.status == "created") {
                            navigate('/Onboarding')
                        }
                        else
                            if (res.data.data.user.status == "onboarding completed") {
                                navigate('/Organization')
                            }
                            else if (res.data.data.user.status == "organization completed") {
                                navigate("/AssetsData")
                            }
                            else
                                if (res.data.data.user.status == "assets completed") {
                                    navigate('/ProfileConnect')
                                }
                                else {
                                    window.location.reload('/Dashboard')
                                }
                    }
                })
                .catch((err) => {   
                    setIsLoader(false);
                    message.error('Please Check Your Credential');
                    console.log(err)
                })
        }
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    return (
        <div>
            <div className='row frame-part mx-0'>
                <div className='col-8 logpart-1'>
                    {/* <div className='log-box1'>
                        <img src={logimg} className='login-img' />
                    </div> */}

                    {/* <div className='log-box2'>
                <div className='log-box21'>bjjbk</div>
                <div className='log-box21'>njkh</div>
            </div> */}

                    {/* <div className='part1-text1'>Extend your social reach with employee advocacy</div>
                    <div className='part1-text2'>The best brand advocates are you and your coworkers, especially as buyers today engage more with employee content than brand content. If you're not tapping into the potential of</div>
                    <div className='part1-text3'>employee advocacy</div> */}
                </div>


                <div className='col-4 logpart-2'>
                    <div className='img-div'>
                        <img src={HeadLogo} className='Framelogo' />
                    </div>

                    <div className='form-section'>

                        <div className='login-line'>Welcome back, good to see you again!</div>

                        <div className='lable-text'>
                            Email Address
                        </div>

                        <div className='input-div'>

                            <input type='text' id="email_id" placeholder='Enter Email' className='input-field' onChange={(e) => { handel(e) }}></input>
                        </div>

                        <div className='lable-text'>Password</div>
                        <div className='input-div'>
                            <input type='password' id="password" placeholder='Enter Password' onChange={(e) => { handel(e) }} className='input-field'></input>
                        </div>

                        <div className="message">
                            <div className='remember_me'>
                                <input type="checkbox" value={Remember} className='remember_check' onChange={() => { setRemeber(!Remember) }} />
                                Remember ME
                            </div>
                            <div>
                                <Link style={{ color: "#116DAA", fontSize: "11px", textDecoration: "none", fontWeight: '200' }}>Forgot your password?</Link>
                            </div>
                        </div>

                        <div className='subbtn-div'>
                            <button className='login-btn' onClick={() => { Login() }}>
                                {
                                    isLoader
                                        ?
                                        <Spin indicator={antIcon} />
                                        :
                                        "Log in"
                                }
                            </button>
                        </div>

                        <div className='mobile_login'>
                            <Link>
                                Log in with Phone Number instead
                            </Link>
                        </div>

                        <div className="signdiv">
                            {/* <div className='d-flex align-item-center justify-content-between'> */}
                            <input type="text" className='sign-line' placeholder="Don't have an account?" />
                            <Link to="/signup">Sign up now</Link>
                            {/* </div> */}
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}
