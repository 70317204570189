import React, { useEffect } from 'react'
import axios from 'axios'


export default function Youtube() {
    useEffect(() => {
        const getQueryParam = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };

        const codes = getQueryParam('code');

        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (codes) {
            axios.get(`${process.env.REACT_APP_BACKEND_API}/youtube/auth/callback`, {
                params: {
                    code: codes,
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    window.close()
                })
                .catch((err) => {
                    console.log(err)
                    window.close()
                })
        }
    }, [])
    return (
        <div>Redirecting....</div>
    )
}
