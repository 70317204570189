import logo from './logo.svg';
import './App.css';

import ConnectProfile from './Components/ConnectProfile';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Notification from './Components/Notification';
import CreateCampagin from './Components/CreateCampagin';
import Signup from './Components/Signup';
import Frame from './Components/Frame'
import Onboarding from './Components/Onboarding';
import Login from './Components/Login'
import Dashboard from './Components/Dashboard';
import Approval from './Components/Approval';
import Photographer from './Components/Photographer'
import Billing from './Components/Billing'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './Components/Auth/PrivateRoute';
import { useState } from 'react';
import { useEffect } from 'react';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import '@progress/kendo-theme-default/dist/all.css';
import Organization from './Components/Organization';
import ProfileConnect from './Components/ProfileConnect';
import axios from 'axios';
import AssetsData from './Components/AssetsData';
import Facebook from './Components/Redirectpage/Facebook';
import Linkedin from './Components/Redirectpage/Linkedin';
import Youtube from './Components/Redirectpage/Youtube';
import { useSelector, useDispatch } from 'react-redux';
import { ProfileRedu } from './Components/store/Slices/ProfileSlice'
import Instagram from './Components/Redirectpage/Instagram';
import Pinterest from './Components/Redirectpage/pinterest'
import Preview from './Components/Preview';
import Twitter from './Components/Redirectpage/Twitter';
import { ChangeSinceDate } from './Components/store/Slices/DateSlice';
import { ChangeUntilDate } from './Components/store/Slices/UntilDateSlice';
import CommanModal from './Comman Components/CommanModal';

function App() {

  const dispatch = useDispatch();

  const [ShowModal, setShowModal] = useState(false);

  function DeleteUserData(storage) {
    if (storage == "locaStorage") {
      let token;
      token = localStorage.getItem('user_Token');

      axios.get(`${process.env.REACT_APP_BACKEND_API}/user/profile`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          if (res.data.user.status !== "completed") {
            if (storage == "localStorage") {
              localStorage.removeItem("Email");
            }
            else {
              sessionStorage.removeItem("Email")
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    else {
      let token;
      token = sessionStorage.getItem('user_Token');

      axios.get(`${process.env.REACT_APP_BACKEND_API}/user/profile`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then((res) => {
          if (res.data.user.status !== "completed") {
            if (storage == "localStorage") {
              localStorage.removeItem("Email");
              localStorage.removeItem("user_Token");
            }
            else {
              sessionStorage.removeItem("Email");
              sessionStorage.removeItem("user_Token");
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

  }

  function checkLogin() {
    if (localStorage.getItem("user_Token")) {
      DeleteUserData("localStorage");
      return true;
    }
    else if (sessionStorage.getItem('user_Token')) {
      DeleteUserData("sessionStorage");
      return true;
    }
    else {
      return false
    }
  }


  const UserProfile = async () => {
    let token;

    if (localStorage.getItem('user_Token')) {
      token = localStorage.getItem('user_Token')
    }
    else {
      token = sessionStorage.getItem('user_Token');
    }

    axios.get(`${process.env.REACT_APP_BACKEND_API}/user/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        dispatch(ProfileRedu(res.data.user))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const GetUserPorofile = () => {
    let token;

    if (localStorage.getItem('user_Token')) {
      token = localStorage.getItem('user_Token')
    }
    else {
      token = sessionStorage.getItem('user_Token');
    }

    axios.get(`${process.env.REACT_APP_BACKEND_API}/profiles/get-profile`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((res) => {
        console.log(res.data.profiles.length == 0)
        if (res.data.profiles.length == 0) {
          setShowModal(true)
        }
      })
  }

  const setDates = () => {
    const d = new Date();
    var months = (d.getMonth() + 1).toString().padStart(2, "0");
    var day = d.getDate().toString().padStart(2, "0");

    dispatch(ChangeSinceDate(Date.parse(d.getFullYear() + "/" + months + "/" + '01') / 1000))
    dispatch(ChangeUntilDate(Date.parse(d.getFullYear() + "/" + months + "/" + day) / 1000))
  }

  useEffect(() => {
    // checkLogin()
    setDates()
    UserProfile()
    if (localStorage.getItem('user_Token')) {
      GetUserPorofile()
    }
    else if(sessionStorage.getItem('user_Token')){
      GetUserPorofile()
    }
  }, [])

  return (
    <div className="App">
      {/* <GoogleOAuthProvider clientId="851700100271-2vesukm7ef5fqeijpg8ilg5p76el9u3k.apps.googleusercontent.com"> */}
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        <ToastContainer />
        <Routes>
          {/* <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path='/' element={<Login/>} />
          </Route> */}
          <Route path="/" element={checkLogin() != true ? <Login /> : <Navigate to="/Dashboard" />} />
          <Route path="/Signup" element={checkLogin() != true ? <Signup /> : <Navigate to="/Dashboard" />} />

          <Route path='/Dashboard' element={checkLogin() == true ? <Dashboard /> : <Navigate to="/" />} />
          <Route path='/Dashboard/*' element={checkLogin() == true ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/Onboarding" element={<Onboarding />} />
          <Route path="/Organization" element={<Organization />} />
          <Route path="/AssetsData" element={<AssetsData />} />
          <Route path="/Photographer" element={checkLogin() == true ? <Photographer /> : <Navigate to="/" />} />
          <Route path="/ProfileConnect" element={<ProfileConnect />} />

          <Route path="/ConnectProfile" element={<ConnectProfile />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/Frame" element={<Frame />} />
          <Route path="/FBRedirect" element={<Facebook />} />
          <Route path="/LinkRedirect" element={<Linkedin />} />
          <Route path="/YTRedirect" element={<Youtube />} />
          <Route path="/instaRedirect" element={<Instagram />} />
          <Route path="/PintRedirect" element={<Pinterest />} />
          <Route path="/Preview" element={<Preview />} />
          <Route path="/twitterRedirect" element={<Twitter />} />
        </Routes>
      </BrowserRouter>
      {/* {
        ShowModal
          ?
          <CommanModal ShowModal={ShowModal} />
          :
          null
      } */}
      {/* </GoogleOAuthProvider> */}
    </div>
  );
}

export default App;
