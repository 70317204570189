import React, { useEffect, useRef } from 'react'
import Steps from '../Steps'
import { Carousel } from 'antd'
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

export default function ProfileConnect() {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: false,
        arrows:false
    };

    const navigate=useNavigate()

    const SliderRef=useRef(null);

    useEffect(()=>{
        console.log(SliderRef)
    })

    return (
        <div className='bg-images'>
            <div className='onbording_box'>

                <div className='steps_main_container'>
                    <Steps steps={3} />
                </div>

                <div className='onboard-line2'>Connect Social Accounts</div>

                <div className='px-1 slider_section'>
                    <button className='prev_arrow_btn' onClick={() => SliderRef?.current?.slickPrev()}>
                        {/* <i class="fa fa-arrow-left" aria-hidden="true"></i> */}
                        <i class="fa-solid fa-chevron-left"></i>
                    </button>
                    {/* <Carousel
                        slidesToShow={4}
                        dots={false}
                        infinite={false}
                        waitForAnimate={true}
                    > */}
                    <Slider ref={SliderRef} {...settings}>
                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/Facebook_icon.png')} className='social_logo' />
                                <div>
                                    Facebook
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/Insta_icon.png')} className='social_logo' />
                                <div>
                                    Instagram
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/Pintrest.png')} className='social_logo' />
                                <div>
                                    Pintrest
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/GMB.png')} className='social_logo' />
                                <div>
                                    Google My Business
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/Youtube_icon.png')} className='social_logo' />
                                <div>
                                    Youtube
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/X_icon.png')} className='social_logo' />
                                <div>
                                    X
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>

                        <div className='d-flex flex-column align-items-center justify-content-between social_box'>
                            <div className='d-flex flex-column align-items-center'>
                                <img src={require('../Assets/Icons/Linkedin_icon.png')} className='social_logo' />
                                <div>
                                    LinkedIn
                                </div>
                            </div>

                            <div className='mt-5'>
                                <button className='connect_btn'>
                                    Connect
                                </button>
                            </div>
                        </div>
                        {/* </Carousel> */}
                    </Slider>
                    <button className='right_arrow_btn' onClick={() => SliderRef?.current?.slickNext()}>
                        {/* <i class="fa fa-arrow-right" aria-hidden="true"></i> */}
                        <i class="fa-solid fa-chevron-right"></i>

                    </button>

                    <div className='d-flex align-items-center justify-content-between w-100 mt-3 px-5 py-3'>
                        <div className='back_btn_section'>
                            <button className='back_btn' onClick={()=>{navigate('/AssetsData')}}>
                                Back
                            </button>
                        </div>

                        <div className='back_btn_section'>
                            <button className='next_btn'>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
