import axios from 'axios'


// facebook connect start
export const LoginFb = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/facebook/auth`)
        .then((res) => {
            // Open the Facebook login page in a new window
            // const popup = window.open(res.data.authUrl, 'Facebook Login',"_self");
            window.location.replace(res.data.authUrl)
        })
        .catch((err) => {
            console.log(err)
        })
}
// facebook connect end

// linkedin connect  start
export const Linkedin = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/linkedin/auth`)
        .then((res) => {
            // Open the Facebook login page in a new window
            const popup = window.open(res.data.authUrl, 'Linkedin Login', 'width=1000,height=600');
        })
        .catch((err) => {
            console.log(err)
        })
}
// linkedin connect  end

// Youtube connect start
export const LoginYT = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/youtube/auth`)
        .then((res) => {
            const popup = window.open(res.data.authUrl, 'Youtube Login', 'width=1000,height=600');
        })
        .catch((err) => {
            console.log(err)
        })
}
// Youtube connect end

// Pinterest Signin start
export const LoginPintrest = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/pinterest/auth`)
        .then((res) => {
            const popup = window.open(res.data.authUrl, 'Pinterest Login', 'width=1000,height=600');
        })
        .catch((err) => {
            console.log(err)
        })
}

// Instagram Login start

export const InstagramLogin = () => {
    axios.get(`${process.env.REACT_APP_BACKEND_API}/instagram/auth`)
        .then((res) => {
            const popup = window.open(res.data.authUrl, 'Pinterest Login', 'width=1000,height=600');
        })
        .catch((err) => {
            console.log(err)
        })
}



// Facebook Pages Get
export const FacebookPages = () => {
    var token;

    if (localStorage.getItem("user_Token")) {
        token = localStorage.getItem("user_Token");
    }
    else {
        token = sessionStorage.getItem("user_Token");
    }

    const pages = axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/page`, {}, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })

    return pages
}