import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Modal, Spin, message } from 'antd';
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons';


export default function Facebook() {
    const navigate = useNavigate();


    const [ShowModal, setShowModal] = useState(false);
    const [PagesList, setPagesList] = useState(null);
    const [isLoader, setIsLoader] = useState(false);
    const [SelectedPage, setSelectedPage] = useState([]);
    const [pageDetails, setPageDetails] = useState([]);
    const [facebookACId, setFacebookACId] = useState();


    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const getPagesDetials = async (access_token) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/pages`, {
            accessToken: access_token
        })
            .then((res) => {
                setPagesList(res.data.data)
                setShowModal(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get the current stored page details and store account_id
    const getProfile = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/profiles/get-platform-profile`, {
            platform: "FACEBOOK"
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                const response = res.data.profiles;
                setFacebookACId(response[response.length-1].user_id);
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Facebook call back
    const FBcallback = () => {

        const getQueryParam = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };

        const codes = getQueryParam('code');

        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (codes) {
            axios.get(`${process.env.REACT_APP_BACKEND_API}/facebook/auth/callback`, {
                params: {
                    code: codes,
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    getPagesDetials(res.data.access_token)
                    getProfile()

                })
                .catch((err) => {
                    if (err.response.data.accessToken) {
                        getPagesDetials(err.response.data.accessToken);
                        getProfile()
                    } else {
                        console.log(err)
                    }
                    // window.close()
                })
        }
    }

    const CancelModal = () => {
        navigate('/Dashboard/GroupsProfile');
    }

    // make page details store in array
    const PagesDetails = (value, check) => {
        if (check.target.checked == true) {
            pageDetails.push({
                'pageId': value.id,
                'accessToken': value.access_token,
            })
        }
        else {
            pageDetails.map(item => {
                // console.log(item.platform_id)
                if (item.platform_id == value.id) {
                    pageDetails.splice(pageDetails.indexOf(item), 1);
                }
            })
        }
    }

    // select page detials and store in a array
    const SelectPages = (value, check) => {
        if (check.target.checked == true) {
            SelectedPage.push({
                'platform': "FACEBOOK",
                'platform_pageId': value.id,
                'platform_id':facebookACId,
                'profile': value.name
            })
        }
        else {
            SelectedPage.map(item => {
                // console.log(item.platform_id)
                if (item.platform_id == value.id) {
                    SelectedPage.splice(SelectedPage.indexOf(item), 1);
                }
            })
        }
    }

    // Save pages details in group
    const SaveProfiles = () => {
        setIsLoader(true)
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: localStorage.getItem("selected_group"),
            connectedAccounts: SelectedPage,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                SavePageDetails()
            })
            .catch((err) => {
                setIsLoader(false)
                message.error("Select Group First")
                console.log(err.error)
            })
    }

    // update page details in profile collection
    const SavePageDetails = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/profiles/update-page-details`, {
            platform: "FACEBOOK",
            user_id: facebookACId,
            pageDetails: pageDetails
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                navigate("/Dashboard/GroupsProfile");
                setIsLoader(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        // callback url
        FBcallback()
    }, [])

    return (
        <Modal title="Connect a Profile" open={ShowModal} footer={null} onCancel={() => { CancelModal() }}>
            <div>
                <div className='select_page_head'>
                    <h4>
                        Select pages to add to your account.
                    </h4>
                </div>

                <hr />

                <div className='pages_list_section'>
                    {
                        PagesList != null
                            ?
                            PagesList.map((pages) => {
                                return (
                                    <div className='d-flex align-items-center justify-content-between my-4'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <img src={pages.picture.data.url != undefined ? pages.picture.data.url : require('../../Assets/Insta_logo.png')} style={{ width: '20%' }} className='profile_img' />

                                            <div>
                                                {
                                                    // pages.Platform == "FACEBOOK"
                                                    //     ?
                                                    <i className="fa-brands fa-facebook fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                                    // :
                                                    // location.state.Platform == "INSTAGRAM"
                                                    //     ?
                                                    //     <i className="fa-brands fa-instagram fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                                    //     :
                                                    //     null
                                                }
                                            </div>


                                            <span className='mx-1'>
                                                {pages.name}
                                            </span>
                                        </div>

                                        <div>
                                            <input type='checkbox' onChange={(e) => { SelectPages(pages, e); PagesDetails(pages, e); }} />
                                        </div>
                                    </div>
                                )
                            })
                            :
                            null
                    }
                </div>

                <div>
                    <button className='Next_page' onClick={()=>{SaveProfiles();}}>
                        {
                            isLoader
                                ?
                                <Spin indicator={antIcon} />
                                :
                                "Next"
                        }
                    </button>
                </div>
            </div>
        </Modal>
    )
}
