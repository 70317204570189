import { Modal, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Connection() {
    const location = useLocation();
    const navigate = useNavigate();

    const [ShowModal, setShowModal] = useState(true);
    const [SelectedPage, setSelectedPage] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [pageDetails, setPageDetails] = useState([]);
    const [facebookACId, setFacebookACId] = useState();

    const CancelModal = () => {
        navigate('/Dashboard/GroupsProfile');
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;


    const SelectPages = (value, check) => {
        if (check.target.checked == true) {
            SelectedPage.push({
                'platform': location.state.Platform,
                'platform_id': value.id,
                'profile': value.picture.data.url
            })
        }
        else {
            SelectedPage.map(item => {
                // console.log(item.platform_id)
                if (item.platform_id == value.id) {
                    SelectedPage.splice(SelectedPage.indexOf(item), 1);
                }
            })
        }
    }

    const PagesDetails = (value, check) => {
        if (check.target.checked == true) {
            pageDetails.push({
                'pageId': value.id,
                'accessToken': value.access_token,
            })
        }
        else {
            pageDetails.map(item => {
                // console.log(item.platform_id)
                if (item.platform_id == value.id) {
                    pageDetails.splice(pageDetails.indexOf(item), 1);
                }
            })
        }
    }

    const SaveProfiles = () => {
        setIsLoader(true)
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: location.state.GroupName,
            connectedAccounts: SelectedPage,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                SavePageDetails()
            })
            .catch((err) => {
                setIsLoader(false)
                message.error("Select Group First")
                console.log(err.error)
            })
    }

    const SavePageDetails = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/profiles/update-page-details`, {
            platform: location.state.Platform,
            user_id: facebookACId,
            pageDetails: pageDetails
        },{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                navigate("/Dashboard/GroupsProfile");
                setIsLoader(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getProfile = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/profiles/get-profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data.profiles)
                const response=res.data.profiles;

                response.map((elem)=>{
                    if (elem.platform === 'FACEBOOK') {
                        setFacebookACId(elem.user_id);
                    }
                })

            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <Modal title="Connect a Profile" open={ShowModal} footer={null} onCancel={() => { CancelModal() }}>
            <div>
                <div className='select_page_head'>
                    <h4>
                        Select pages to add to your account.
                    </h4>
                </div>

                <hr />

                <div className='pages_list_section'>
                    {
                        (location.state.pages).map((pages) => {
                            return (
                                <div className='d-flex align-items-center justify-content-between my-4'>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <img src={pages.picture.data.url != undefined ? pages.picture.data.url : require('../Assets/Insta_logo.png')} style={{ width: '20%' }} className='profile_img' />

                                        <div>
                                            {
                                                location.state.Platform == "FACEBOOK"
                                                    ?
                                                    <i className="fa-brands fa-facebook fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                                    :
                                                    location.state.Platform == "INSTAGRAM"
                                                        ?
                                                        <i className="fa-brands fa-instagram fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                                        :
                                                        null
                                            }
                                        </div>


                                        <span className='mx-1'>
                                            {pages.name}
                                        </span>
                                    </div>

                                    <div>
                                        <input type='checkbox' onChange={(e) => { SelectPages(pages, e); PagesDetails(pages, e); }} />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div>
                    <button className='Next_page' onClick={() => { SaveProfiles() }}>
                        {
                            isLoader
                                ?
                                <Spin indicator={antIcon} />
                                :
                                "Next"
                        }
                    </button>
                </div>
            </div>
        </Modal>
    )
}
