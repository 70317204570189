import React, { useEffect, useState } from 'react'
import axios from 'axios';

export default function Images({ Id }) {
    const [Images, setImages] = useState();

    function GetInstagramDetails() {
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/profile`, {
            insta_id: Id
        }, {
            headers: {
                'Authorization': `Berear ${token}`
            }
        })
            .then((res) => {
                console.log("imagess",res.data)
                setImages(res.data.profile_picture_url)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(()=>{
        GetInstagramDetails()
    },[])

    return (
        <img src={Images} className='post_header_img' />
    )
}
