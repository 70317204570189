import axios from 'axios'
import React, { useEffect, useState } from 'react'
import io from "socket.io-client"
import { useLocation } from 'react-router-dom'
import { message } from 'antd';
import Cookies from 'js-cookie';

// const socket=io.connect(`ws://localhost:8080/uploadFile`)

export default function Message() {

    const [Conversation, setConversation] = useState(null);
    const [PrevMessage, setPrevMessage] = useState(null);
    const [messages, setMessage] = useState(null);
    const location = useLocation();

    const GetConver = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/GetConversation`, {
            pageId: JSON.parse(Cookies.get('Oolook_default'))
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                if ((res.data.data).length != 0) {
                    setConversation(res.data.data)
                }
                else {
                    setConversation(null)
                }
                // setPrevMessage((res.data.data[0].messages.data).reverse())
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const sendMessage = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (messages == null) {
            message.warning("Type Some Message")
        }
        else {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/SendMessage`, {
                pageId: JSON.parse(Cookies.get('Oolook_default')),
                recipient_id: Conversation[0].participants.data[0].id,
                text: messages
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    GetConver()
                })
                .catch((err) => {
                    message.error("There is some error now. Try Again...")
                    console.log(err)
                })
        }
    }

    const ReplyToComment = (commentData) => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (commentData.platform == "facebook") {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/reply-to-comment`, {
                'user_id': '187943791029794',
                'message': messages,
                "comment_id": commentData.post_id,
                "page_id": commentData.Page_Id
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const CommentDesigns = () => {
        return (
            <div>
                <div className='comment_reply_section mt-4'>
                    <div className='d-flex align-items-center justify-content-between comment-reply_header'>
                        <div className='d-flex align-items-center'>
                            {
                                location.state.data.platform == "facebook"
                                    ?
                                    <img src={`https://graph.facebook.com/${location.state.data.Page_Id}/picture?return_ssl_resources=true`} className='post_header_img' />
                                    :
                                    // <Images Id={location.state.data.Page_Id} />
                                    null
                            }
                            <div className='mx-2'>
                                <span className='mx-1'>
                                    {
                                        location.state.data.platform == "facebook"
                                            ?
                                            <i class="fa-brands fa-facebook"></i>
                                            :
                                            null
                                    }
                                    {
                                        location.state.data.platform == "instagram"
                                            ?
                                            <i class="fa-brands fa-instagram"></i>
                                            :
                                            null
                                    }
                                </span>


                                <span>
                                    {
                                        location.state.data.user_name
                                    }
                                </span>
                            </div>
                        </div>


                        <div>
                            <span className='comment_date'>
                                {
                                    Months[location.state.data.time.split("T")[0].split("-")[1].split("0")[1] - 1] + " " + location.state.data.time.split("T")[0].split("-")[2] + ", " + location.state.data.time.split("T")[0].split("-")[0]
                                }
                            </span>
                        </div>
                    </div>

                    <div className='comment_post'>
                        <span>
                            {
                                location.state.data.caption
                            }
                        </span>

                        <div className='mt-3'>
                            <img src={location.state.data.post_url} className='comment_posts_img' />
                        </div>

                        <div className='d-flex align-items-center justify-content-around mt-2'>
                            <span>
                                <i class="fa fa-thumbs-up"></i> {location.state.data.Total_like}
                            </span>

                            <span>
                                <i class="fa fa-comment"></i> {location.state.data.Total_comments}
                            </span>

                            <span>
                                <i class="fa fa-share"></i>
                            </span>
                        </div>
                    </div>

                    <div className='comment_post d-flex'>
                        <div>
                            <img src={require('../Assets/dummy_user.png')} className='comment_user' />
                        </div>

                        <div className='d-flex flex-column user_comment'>
                            <span>
                                <b>User_name</b>
                            </span>

                            <span>
                                {
                                    location.state.data.comments
                                }
                            </span>
                        </div>
                    </div>

                    <div className='reply_section d-flex'>
                        <div>
                            {
                                location.state.data.platform == "facebook"
                                    ?
                                    <img src={`https://graph.facebook.com/${location.state.data.Page_Id}/picture?return_ssl_resources=true`} className='post_header_img_comment' />
                                    :
                                    // <Images Id={location.state.data.Page_Id} />
                                    null
                            }
                        </div>

                        <div className='d-flex flex-column user_comment'>
                            <span>
                                <b>
                                    {
                                        location.state.data.user_name
                                    }
                                </b>
                            </span>

                            <span>
                                <textarea onChange={(e) => { setMessage(e.target.value) }} className='comment_reply_textarea' placeholder='Reply'>

                                </textarea>

                                <div>
                                    <button className='reply_btn' onClick={() => { ReplyToComment(location.state.data) }}>
                                        <i class="fa fa-location-arrow"></i>
                                    </button>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


    useEffect(() => {
        console.log(location.state.data)
        // if (Cookies.get('Oolook_default') == undefined) {
        //     message.warning('Select Page for Message')
        // }
        // else {
        //     GetConver()
        // }
    }, [])
    return (
        <div style={{ background: "#f3f4f4" }}>
            {/* <div className='message_head_box'>
                Private Conversation between {Conversation != null ? Conversation[0].participants.data[1].name : null} and {Conversation != null ? Conversation[0].participants.data[0].name : null}
            </div> */}

            <div className='message-box'>
                {/* <div style={{ background: "#d24e62", height: 10, }}></div> */}
                <div className='row'>
                    <div className='col-3'>
                        <div className='one-box'>
                            <div style={{ marginLeft: 20, position: "relative", top: 30 }}>History</div>
                            <div style={{ marginLeft: 20, marginTop: 60 }}>Private Message History</div>
                            <ul className='message_list'>
                                <li style={{ marginLeft: 40 }}>Private Reply</li>
                                {
                                    Conversation != null
                                        ?
                                        Conversation.map((elem) => {
                                            return (
                                                <li style={{ marginLeft: 40 }} onClick={() => { setPrevMessage((elem.messages.data).reverse()) }}>{elem.participants.data[0].name}</li>
                                            )
                                        })
                                        :
                                        null
                                }

                            </ul>
                        </div>
                    </div>
                    <div className='col-6' style={{ backgroundColor: "#fff" }}>
                        {
                            location.state != null
                                ?
                                CommentDesigns()
                                :
                                <div className='mid-box'>
                                    <div className='mid-link'>Mark as Complete</div>
                                    {
                                        PrevMessage != null
                                            ?
                                            PrevMessage.map((elem) => {
                                                if (elem.from.name == "Imagine Jaipur ") {
                                                    return (
                                                        <div className='d-flex align-items-end px-3 py-2'>
                                                            <div className='d-flex flex-column align-items-center w-100 mx-2'>
                                                                <div className='mid-sm-box_send'>
                                                                    {elem.message}
                                                                </div>
                                                                <span className='message_time'>{(elem.created_time).split("T")[0] + ",  " + ((elem.created_time).split("T")[1]).split(":")[0] + ":" + ((elem.created_time).split("T")[1]).split(":")[1]}</span>
                                                            </div>

                                                            <div>
                                                                <img className='conver_img' src={require('../Assets/Review_img.png')} />
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div className='d-flex align-items-end px-3 py-2'>
                                                            <div>
                                                                <img className='conver_img' src={require('../Assets/Review_img.png')} />
                                                            </div>

                                                            <div className='d-flex flex-column align-items-center w-100 mx-2'>
                                                                <div className='mid-sm-box1'>
                                                                    {elem.message}
                                                                </div>
                                                                <span className='message_time'>{(elem.created_time).split("T")[0] + ",  " + ((elem.created_time).split("T")[1]).split(":")[0] + ":" + ((elem.created_time).split("T")[1]).split(":")[1]}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            })
                                            :
                                            null
                                    }
                                    <div className='mid-sm-box2'>
                                        <textarea className='message_area' onChange={(e) => { setMessage(e.target.value) }} placeholder='Enter Text'>

                                        </textarea>

                                        <div className='row message_options'>
                                            <div className='col-1'>
                                                <img src={require('../Assets/bookmark.png')} className='circle-box1' />
                                            </div>
                                            <div className='col-1'>
                                                <img src={require('../Assets/mood.png')} className='circle-box1' />
                                            </div>
                                            <div className='col-1'>
                                                <img src={require('../Assets/icon2-icon.png')} className='circle-box1' />
                                            </div>
                                            <div className='col-1'>
                                                <img src={require('../Assets/camera-icon.png')} className='circle-box1' />
                                            </div>
                                            <div className='col-8'>
                                            </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-between mt-2'>
                                            <div className='d-flex align-items-center my-2'>
                                                <input type='checkbox' />
                                                <label className='mx-2'>
                                                    Mark all complete
                                                </label>
                                            </div>

                                            <div>
                                                <button className='message_send_btn' onClick={() => { sendMessage() }}>
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='col-3'>
                        <div className='one-box'>
                            <div className='box-div1'>
                                <img src={require('../Assets/circle-inbox.png')} className='circle-box' />
                            </div>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {
                                    location.state != null
                                        ?
                                        <div>
                                            {
                                                location.state.data.platform == "facebook"
                                                    ?
                                                    <img src={require('../Assets/FB_logo.png')} className='circle-box1' />
                                                    :
                                                    <img src={require('../Assets/Social media icons.png')} className='circle-box1' />
                                            }
                                        </div>
                                        :
                                        null

                                }
                                <div style={{ marginTop: 58, marginLeft: 10, fontWeight: "500" }}>Private</div>
                            </div>

                            <div style={{ color: "#4468b1", marginTop: 10, textDecoration: "underline", fontSize: 14, marginLeft: 30 }}>
                                {
                                    Conversation != null
                                        ?
                                        Conversation[0].participants.data[1].name
                                        :
                                        null
                                }
                            </div>

                            <div style={{ color: "grey", marginLeft: 30, fontSize: 14, marginTop: 30 }}>0 Followers</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
