import { createSlice } from "@reduxjs/toolkit";

const SideSlice=createSlice({
    name:"SideSlice",
    initialState:[],
    reducers:{
        StoreSideBar(state,action){
            state.length=0
            state.push(action.payload)
        }
    }
})

export {SideSlice}

export const {StoreSideBar}=SideSlice.actions