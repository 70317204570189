import axios from 'axios'
import React, { useState } from 'react'

import { message } from 'antd';

export default function AccountSetting() {

    const [Changepass, setChangePassword] = useState();
    const [oldPassword, setOldPassword] = useState();

    const ChangePassoword = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/user/change-pwd`, {
            'password': oldPassword,
            'newPassword': Changepass
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                message.success("Password Changed. Login Again")
                if (localStorage.getItem('user_Token')) {
                    localStorage.removeItem("user_Token");
                    localStorage.removeItem("Email");
                    window.location.reload();
                }
                else {
                    sessionStorage.removeItem("user_Token");
                    sessionStorage.removeItem("Email");
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const DeleteAccount = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.delete(`${process.env.REACT_APP_BACKEND_API}/user/delete`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then((res) => {
                if (localStorage.getItem("user_Token")) {
                    localStorage.removeItem("user_Token");
                    localStorage.removeItem("Email");
                    window.location.reload();
                }
                else {
                    sessionStorage.removeItem("user_Token");
                    sessionStorage.removeItem("Email");
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <div className='acc-head'>Account</div>

            <div className='account-box'>
                <div className='acc-email'>Email Address</div>

                <div className='account-input'>
                    <input type='text' id="email_id" className='Acc-input-field'></input>
                </div>
                <hr />

                <div className='row'>
                    <div className='col-6'></div>

                    <div className='col-3'>
                        <button className='acc-btn1'>Verify Email</button>
                    </div>

                    <div className='col-3'>
                        <button className='acc-btn2'>Save Changes</button>
                    </div>
                </div>
            </div>

            <div className='account-box2'>
                <div className='acc-email'>Password</div>

                <div className='account-input'>
                    <input type='password' placeholder='Enter Current Password' onChange={(e) => { setOldPassword(e.target.value) }} className='Acc-input-field' />
                    <input type='password' placeholder='Enter New Password' onChange={(e) => { setChangePassword(e.target.value) }} className='Acc-input-field' />
                </div>

                <div className='pass-text'>If you’d like to change your password, enter a new password here</div>
                <hr />

                <div className='row'>
                    <div className='col-sm-9'></div>
                    <div className='col-sm-3'>
                        <button className='acc-btn2 my-2' onClick={() => { ChangePassoword() }}>Change Password</button>
                    </div>
                </div>
            </div>

            <div className='d-flex delete-btn-box'>
                <button className='acc-dlt' onClick={() => { DeleteAccount() }}>Delete My Account</button>
            </div>

            <div className='acc-head'>
                Billing
            </div>

            <div className='account-box3'>
                <div className='acc-box3-text'>Plans</div>
            </div>

            <div className='account-box4'>
                <div className='acc-box3-text'>Basics + Trial</div>
                <div className='acc-box4-text'>Your Free Trial ends in 7 days on 14 February 2024</div>
            </div>
        </div>
    )
}

