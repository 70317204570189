import React, { useState } from 'react'
import Steps from '../Steps'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Organization() {
    const [Mobile, setMobile] = useState();
    const navigate = useNavigate();

    const [DataOrganization, setOrganizationData] = useState({
        Name: "",
        size: "",
        address: "",
        state: "",
        city: "",
        pincode: ""
    })

    function handelOrganization(e) {
        e.preventDefault();
        const newdata = { ...DataOrganization }
        newdata[e.target.id] = e.target.value;
        setOrganizationData(newdata);
    }


    function OrganizationDataSend() {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding2`, {
            "org_name": DataOrganization.Name,
            "org_address": DataOrganization.address,
            "org_contact": Mobile,
            "org_size": DataOrganization.size,
            "org_state": DataOrganization.state,
            "org_city": DataOrganization.city,
            "org_pincode": DataOrganization.pincode
        }, {
            headers: {
                'Authorization': `Barear ${token}`
            }
        })
            .then((res) => {
                // setDataView('Assets')
                UpdateStatus()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const CreateFirstGroup=()=>{
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`,{
            groupName:DataOrganization.Name,
            connectedAccounts:[]
        },{
            headers:{
                'Authorization':`Barear ${token}`
            }
        })
        .then((res)=>{
            navigate("/AssetsData");
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    async function UpdateStatus() {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, {
            status: 'organization completed'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                CreateFirstGroup()
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <div className='bg-images'>
            <div className='onbording_box'>

                <div className='steps_main_container'>
                    <Steps steps={2} />
                </div>


                <>
                    <div className='onboard-line2'>Select Your Primary Goals</div>

                    <div className='form_section'>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                Organization Name
                            </label>
                            <input type="text" id="Name" value={DataOrganization.Name} onChange={(e) => handelOrganization(e)} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                Organization Size
                            </label>
                            <input type="number" id="size" value={DataOrganization.size} onChange={(e) => { handelOrganization(e) }} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                Company Phone Number
                            </label>
                            <PhoneInput
                                placeholder="Enter Phone No."
                                defaultCountry="IN"
                                value={Mobile}
                                onChange={setMobile} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                Company Address
                            </label>
                            <input type="text" id="address" value={DataOrganization.address} onChange={(e) => { handelOrganization(e) }} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                State
                            </label>
                            <input type="text" id="state" value={DataOrganization.state} onChange={(e) => { handelOrganization(e) }} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                City
                            </label>
                            <input type="text" id="city" value={DataOrganization.city} onChange={(e) => { handelOrganization(e) }} />
                        </div>

                        <div className='d-flex flex-column form_fields'>
                            <label>
                                Pincode
                            </label>
                            <input type="text" id="pincode" value={DataOrganization.pincode} onChange={(e) => { handelOrganization(e) }} />
                        </div>
                    </div>

                    <div className='d-flex align-items-center justify-content-between w-100 mt-3 px-5 py-3'>
                        <div className='back_btn_section'>
                            <button className='back_btn' onClick={() => { navigate('/Onboarding') }}>
                                Back
                            </button>
                        </div>

                        <div className='back_btn_section' onClick={() => { OrganizationDataSend() }}>
                            <button className='next_btn'>
                                Next
                            </button>
                        </div>
                    </div>
                </>
            </div>
        </div>
    )
}
