import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker, Modal, Spin, message } from 'antd';
import dayjs from 'dayjs';
import { ChangeSinceDate } from './store/Slices/DateSlice';
import { ChangeUntilDate } from './store/Slices/UntilDateSlice';
import Dropdown from './Filter Dropdown/Dropdown';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Checkbox } from 'antd'
import Images from '../Comman Components/Images';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/DD/MM';

export default function PostReport() {

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    });



    const FilterData = [
        [
            "Source", [
                "Instagram",
                "Facebook",
                "Pinterest",
            ]
        ],
        [
            "Competitor Source", [
                "All Instagram",
                "All Facebook",
                "All Pinterest",
                "Viewing All"
            ]
        ],
        [
            "Type", [
                "Image",
                "Video"
            ]
        ]
    ];


    const MonthsString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];


    const [showFilter, setshowFilter] = useState(false);
    const [Posted, setPosted] = useState(null);
    const [instID, setInstaId] = useState('')
    const [FBId, setFbId] = useState('');
    const [PlatformFilter, setPlatformFilter] = useState(false);
    const [TypeFilter, setTypeFilter] = useState(false);
    const [Totalpages, setTotalPages] = useState(null);

    const [Platform, setPlatform] = useState(["INSTAGRAM", "FACEBOOK", "PINTEREST"]);  //default filter
    const [Type, setType] = useState(['Image']);
    const [MediaType, setMediaType] = useState('image');
    const [CurrentPage, setCurrentPage] = useState(1);

    const [ShowPreview, setShowpreview] = useState(false);

    const [previewUrl, setPreviewUrl] = useState();

    const [PreviewType, setPreviewType] = useState();

    const [loader, setLoader] = useState(true);

    const [ActiveTab, setActiveTab] = useState('Post Report');

    const dispatch = useDispatch();


    const d = new Date();
    var months = (d.getMonth() + 1).toString().padStart(2, "0");
    var day = d.getDate().toString().padStart(2, "0");

    // Get userPosts
    const GetPosts = async () => {
        setLoader(true);
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/posts/get-filtered`, {
            groupName: localStorage.getItem("oolook_default_group"),
            startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            endDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
            mediaType: MediaType,
            page: CurrentPage,
            pageSize: '12',
            platforms: Platform
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setLoader(false);
                setTotalPages(res.data.totalPages)
                setPosted(res.data.posts)
            })
            .catch((err) => {
                setLoader(false);
                message.error("There is some error try again");
                console.log(err)
            })
    }

    const FilterPlatform = (PlatformName, checked) => {
        if (PlatformName != "Viewing All") {
            if (checked.target.checked == true) {
                Platform.push(JSON.parse(JSON.stringify(PlatformName).toUpperCase()));
                setPlatformFilter(false);
                GetPosts()
            }
            else {
                Platform.map((elem) => {
                    if (elem === JSON.parse(JSON.stringify(PlatformName).toUpperCase())) {
                        if (checked.target.checked == false) {
                            Platform.splice(Platform.indexOf(JSON.parse(JSON.stringify(PlatformName).toUpperCase())), 1);
                            setPlatformFilter(false);
                            GetPosts()
                        }
                        else {
                            GetPosts()
                        }
                    }
                })
            }
        }
        else {
            console.log(Platform)
        }
    }

    const ChangeType = (FilterType, checked) => {
        if (checked.target.checked == true) {
            if (FilterType == "Image") {
                Type.push("Image");
                if (Type.length == 2) {
                    setMediaType('');
                    GetPosts();
                }
                else {
                    setMediaType('image');
                    GetPosts();
                }

                setTypeFilter(false)
            }
            else {
                Type.push("Video");
                if (Type.length == 2) {
                    setMediaType('');
                    GetPosts();
                }
                else {
                    setMediaType('video');
                    GetPosts();
                }

                setTypeFilter(false)
            }
        }
        else {
            if (FilterType == "Image") {
                Type.splice(Type.indexOf("Image"), 1);
                setTypeFilter(false);
                GetPosts();
            }
            else {
                Type.splice(Type.indexOf("Video"), 1);
                setTypeFilter(false);
                GetPosts();
            }
        }
    }

    const Pagenation = () => {
        let pages = [];

        for (var i = 1; i <= Totalpages; i++) {
            pages.push(<span className={CurrentPage === i ? 'pages active-page' : 'pages'} onClick={(e) => { setCurrentPage(e.target.outerText) }}>{i}</span>)
        }

        return pages

    }

    const PostReport = () => {
        return (
            <>
                <div className='d-flex align-items-center justify-content-center' style={{ flexWrap: 'wrap' }}>
                    {
                        Posted != null
                            ?
                            Posted.map((elem) => {
                                return (
                                    <div className='socials uploaded_posts instagram mt-5 mx-2 box_size'>

                                        <div className='d-flex align-items-center post_username'>
                                            <div>
                                                {
                                                    elem.platform == "facebook"
                                                        ?
                                                        <img src={`https://graph.facebook.com/${elem.from.id}/picture?return_ssl_resources=true`} className='user_profile_image' />
                                                        :
                                                        null
                                                }

                                                {
                                                    elem.platform == "instagram"
                                                        ?
                                                        <>
                                                        {<Images Id={elem.id} />}
                                                        </>
                                                        :
                                                        null
                                                }
                                            </div>

                                            <div className='d-flex flex-column align-items-center mx-2'>

                                                <div className='d-flex align-items-center w-100'>
                                                    {
                                                        elem.created_time
                                                            ?
                                                            <i class="fa-brands fa-facebook"></i>
                                                            :
                                                            elem.timestamp
                                                                ?
                                                                <i class="fa-brands fa-instagram"></i>
                                                                :
                                                                <i class="fa-brands fa-pinterest"></i>
                                                    }
                                                    <span className='username'>
                                                        {
                                                            elem.platform == "facebook"
                                                                ?
                                                                elem.from.name
                                                                :
                                                                null
                                                        }

                                                        {
                                                            elem.platform == "instagram"
                                                                ?
                                                                elem.username
                                                                :
                                                                null
                                                        }

                                                        {
                                                            elem.platform == "pinterest"
                                                                ?
                                                                elem.board_owner.username
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                </div>

                                                <div>
                                                    <span className='post_date'>
                                                        {
                                                            elem.created_time
                                                                ?
                                                                (elem.created_time).split("T")[0]
                                                                :
                                                                elem.timestamp
                                                                    ?
                                                                    (elem.timestamp).split("T")[0]
                                                                    :
                                                                    null
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <span className='post_caption' title={
                                                elem.platform == "facebook"
                                                    ?
                                                    elem.message
                                                    :
                                                    elem.platform == 'instagram'
                                                        ?
                                                        elem.caption
                                                        :
                                                        null
                                            }>
                                                {
                                                    elem.platform == "facebook"
                                                        ?
                                                        elem.message
                                                        :
                                                        elem.platform == 'instagram'
                                                            ?
                                                            elem.caption
                                                            :
                                                            null
                                                }
                                            </span>

                                            <div>
                                                {
                                                    elem.platform == "facebook"
                                                        ?
                                                        <>
                                                            {
                                                                elem.attachments != undefined
                                                                    ?
                                                                    <>
                                                                        {
                                                                            elem.attachments.data[0].type == "photo"
                                                                                ?
                                                                                <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.attachments.data[0].media.image.src); setPreviewType('image'); setShowpreview(true); }}>
                                                                                    <img src={elem.attachments.data[0].media.image.src} className='post_imgs' />
                                                                                </button>
                                                                                :
                                                                                <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.attachments.data[0].media.image.src); setPreviewType('video'); setShowpreview(true); }}>
                                                                                    <video src={elem.attachments.data[0].media.source} className='post_imgs' controls></video>
                                                                                </button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </>
                                                        :
                                                        elem.platform == "instagram"
                                                            ?
                                                            <>
                                                                {
                                                                    elem.media_type == "IMAGE"
                                                                        ?
                                                                        <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.media_url); setPreviewType('image'); setShowpreview(true); }}>
                                                                            <img src={elem.media_url} className='post_imgs' />
                                                                        </button>
                                                                        :
                                                                        <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.media_url); setPreviewType('video'); setShowpreview(true); }}>
                                                                            <video src={elem.media_url} className='post_imgs' controls></video>
                                                                        </button>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    elem.media.media_type == "image"
                                                                        ?
                                                                        <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.media.images["150x150"].url); setPreviewType('image'); setShowpreview(true); }}>
                                                                            <img src={elem.media.images["150x150"].url} className='post_imgs' />
                                                                        </button>
                                                                        :
                                                                        <button className='w-100 view_image_btn' onClick={() => { setPreviewUrl(elem.media_url); setPreviewType('video'); setShowpreview(true); }}>
                                                                            <video src={elem.media_url} className='post_imgs'></video>
                                                                        </button>
                                                                }
                                                            </>
                                                }
                                            </div>


                                            <div>
                                                <table className='post_details table'>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Public Engagements
                                                            </td>

                                                            <td>
                                                                {
                                                                    elem.platform == "instagram"
                                                                        ?
                                                                        elem.like_count + elem.comments_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "facebook"
                                                                        ?
                                                                        elem.likes.summary.total_count + elem.comments.summary.total_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "pinterest"
                                                                        ?
                                                                        "NA"
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Likes
                                                            </td>

                                                            <td>
                                                                {
                                                                    elem.platform == "instagram"
                                                                        ?
                                                                        elem.like_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "facebook"
                                                                        ?
                                                                        elem.likes.summary.total_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "pinterest"
                                                                        ?
                                                                        "NA"
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                Comments
                                                            </td>

                                                            <td>
                                                                {
                                                                    elem.platform == "instagram"
                                                                        ?
                                                                        elem.comments_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "facebook"
                                                                        ?
                                                                        elem.comments.summary.total_count
                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    elem.platform == "pinterest"
                                                                        ?
                                                                        "NA"
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            null
                    }
                </div>

                <div>
                    {
                        Totalpages != null
                            ?
                            <div className='pagenation_section'>
                                <Pagenation />
                            </div>
                            :
                            null
                    }
                </div>
            </>
        )
    }

    const CompetitorPostReport = () => {

        return (
            <div className='d-flex align-items-center justify-content-center' style={{ flexWrap: 'wrap' }}>
                {
                    Posted
                        ?
                        Posted.map((elem) => {
                            return (
                                <div className='socials uploaded_posts instagram mt-5 mx-2 box_size'>

                                    <div className='d-flex align-items-center post_username'>
                                        <div>
                                            <img src={require('../Assets/Rectangle.png')} className='user_profile_image' />
                                        </div>

                                        <div className='d-flex flex-column align-items-center mx-2'>

                                            <div className='d-flex align-items-center w-100'>
                                                <i class="fa-brands fa-facebook"></i>

                                                <span className='username'>
                                                    Starbucks
                                                </span>
                                            </div>

                                            <div>
                                                <span className='post_date'>
                                                    2024-04-06T11:07:01+0000
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <span className='post_caption'>
                                            Captions
                                        </span>

                                        <div>
                                            <img src={require('../Assets/dummy_post.png')} className='post_imgs' />
                                        </div>
                                        <div>
                                            <table className='post_details table'>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Public Engagements
                                                        </td>

                                                        <td>
                                                            188,213
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Likes
                                                        </td>

                                                        <td>
                                                            187,299
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Comments
                                                        </td>

                                                        <td>
                                                            914
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        null
                }

            </div >
        )
    }

    useEffect(() => {
        if (Cookies.get('Oolook_default_Insta') != undefined) {
            setInstaId(JSON.parse(Cookies.get('Oolook_default_Insta')));
        }

        if (Cookies.get('Oolook_default')) {
            setFbId(JSON.parse(Cookies.get('Oolook_default')));
        }

        GetPosts();
    }, [Sinceselector, untilSelector, Platform, CurrentPage, MediaType])

    if (loader) {
        return (
            <div>
                <div id='overlay'></div>
                <div className='loader_section'>
                    <Spin size='large' />
                </div>
            </div>
        )
    }

    return (
        <div className='post_report_section'>
            <div className='approval_header performace_header'>
                <div className='performance_inner'>
                    <div className='performace_subhead align-items-center'>
                        <div>
                            <h4>Profile Performance</h4>
                        </div>
                        <div className='mx-2'>
                            <i class="fa fa-star fa-1x" aria-hidden="true"></i>
                        </div>
                    </div>

                    <span className='date_txt'>Activity from {MonthsString[new Date(Sinceselector[0] * 1000).getMonth()] + " " + new Date(Sinceselector[0] * 1000).getDate() + ", " + new Date(Sinceselector[0] * 1000).getFullYear()} -
                        {MonthsString[new Date(untilSelector[0] * 1000).getMonth()] + " " + new Date(untilSelector[0] * 1000).getDate() + ", " + new Date(untilSelector[0] * 1000).getFullYear()}
                        <label className='highlight_txt'> {" "} ( multiple</label> time zones)</span>
                </div>

                <div className='d-flex align-items-center'>
                    <div>
                        <span className='Inbox_remaining performace_date'>
                            <RangePicker
                                format={dateFormat}
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                defaultValue={[dayjs(d.getFullYear() + "/" + '01' + "/" + months, dateFormat), dayjs(d.getFullYear() + "/" + day + "/" + months, dateFormat)]}
                                onChange={(e) => { dispatch(ChangeSinceDate(Date.parse(e[0].$d) / 1000)); dispatch(ChangeUntilDate(Date.parse(e[1].$d) / 1000)); }} />

                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-upload" style={{ marginRight: '5px' }}></i>
                            Export
                        </span>
                    </div>

                    <div>
                        <button className='Inbox_remaining Filter_btn' onClick={() => { setshowFilter(!showFilter) }}>
                            <img src={require('../Assets/Filter.png')} />
                            Filters
                        </button>
                    </div>
                </div>
            </div>

            {/* <hr /> */}

            {/* Filters */}
            <div>
                <div className='d-flex align-items-center justify-content-between filter_section'>
                    <div className='d-flex align-items-center w-75'>
                        {/* Platform Dropdown */}
                        <div style={{ width: '30%', marginLeft: '10px', marginRight: '10px' }}>
                            <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setPlatformFilter(!PlatformFilter) }}>
                                <div className='d-flex flex-column mx-2'>
                                    <div className='filter_head'>
                                        Platform
                                    </div>

                                    {/* Options */}
                                    <div className='filter_selcted_option'>
                                        {
                                            Platform.length == 1
                                                ?
                                                Platform[0]
                                                :
                                                Platform.length + " Platforms Selected"
                                        }
                                    </div>
                                </div>

                                <div>
                                    <i class={PlatformFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                </div>
                            </button>

                            {/* options list */}
                            {
                                PlatformFilter
                                    ?
                                    <div className='option_list'>
                                        <ul>
                                            {
                                                FilterData[0][1].map((elem) => {
                                                    return (
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <Checkbox
                                                                    key={elem}
                                                                    value={JSON.parse(JSON.stringify(elem).toUpperCase())}
                                                                    checked={Platform.includes(JSON.parse(JSON.stringify(elem).toUpperCase()))}
                                                                    onClick={(e) => { FilterPlatform(elem, e) }}
                                                                >
                                                                    {elem}
                                                                </Checkbox>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    :
                                    null
                            }
                        </div>

                        {/* <Dropdown FilterOption={FilterData[1]} /> */}

                        {/* Type Dropdown */}
                        <div style={{ width: '30%', marginLeft: '10px', marginRight: '10px' }}>
                            <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setTypeFilter(!TypeFilter) }}>
                                <div className='d-flex flex-column mx-2'>
                                    <div className='filter_head'>
                                        {FilterData[2][0]}
                                    </div>

                                    {/* Options */}
                                    <div className='filter_selcted_option'>
                                        {
                                            Type.length != 1
                                                ?
                                                "Image, Video"
                                                :
                                                Type
                                        }{" "}
                                    </div>
                                </div>

                                <div>
                                    <i class={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                </div>
                            </button>

                            {/* options list */}
                            {
                                TypeFilter
                                    ?
                                    <div className='option_list'>
                                        <ul>
                                            {
                                                FilterData[2][1].map((elem) => {
                                                    return (
                                                        <li>
                                                            <div className='d-flex align-items-center'>
                                                                <Checkbox
                                                                    key={elem}
                                                                    value={elem}
                                                                    checked={Type.includes(elem)}
                                                                    onClick={(e) => { ChangeType(elem, e) }}
                                                                >
                                                                    {elem}
                                                                </Checkbox>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>

                    <div>
                        <label className='text_right'>
                            Clear All
                        </label>
                    </div>
                </div>

            </div>

            {/* tabs */}
            <div className='d-flex w-100 mt-2'>
                <button className={ActiveTab == "Post Report" ? 'w-50 post_performance_tabs post_performance_tabs_active' : 'w-50 post_performance_tabs'} onClick={() => { setActiveTab('Post Report') }}>
                    Post Report
                </button>

                <button className={ActiveTab == "Competitor Post Report" ? 'w-50 post_performance_tabs post_performance_tabs_active' : 'w-50 post_performance_tabs'} onClick={() => { setActiveTab('Competitor Post Report') }}>
                    Competitor Post Report
                </button>
            </div>

            <Modal
                open={ShowPreview}
                footer={false}
                onCancel={() => { setShowpreview(false) }}
                closable={false}
                className='preview_img_modal'
            >
                <div style={{ textAlign: 'center' }}>
                    {
                        PreviewType == "image"
                            ?
                            <img className='preview_img' src={previewUrl} />
                            :
                            <video className='preview_img' src={previewUrl}></video>
                    }
                </div>
            </Modal>

            {/* Posts */}
            {
                ActiveTab == "Post Report"
                    ?
                    <PostReport />
                    :
                    <CompetitorPostReport />
            }
        </div>
    )
}
