import React from 'react'

export default function Reportprofile() {
    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Profiles
                    </label>

                    <label className='label_sub_head'>
                        Review aggregate activity for pages and the posts published during the selected time period.
                    </label>
                </div>

                <div style={{ overflowX: "auto" }}>
                    <table className='comp-profile-tbl table'>
                        <thead>
                            <tr>
                                <th style={{ width: "50%" }}>Profile</th>
                                <th>Audience</th>
                                <th className='text-right'>Net Audience Growth</th>
                                <th className='text-right'>Published Posts</th>
                                <th className='text-right'>Impression</th>
                                <th className='text-right'>Engagements</th>
                                <th className='text-right'>Engagements Rate (per Impression)</th>
                                <th className='text-right'>Video View</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className='pro-tbl-name1'>
                                    <div className='d-flex flex-column'>
                                        <span>
                                            Reporting Period
                                        </span>
                                        <span className='report_dates'>
                                            Apr 1, 2023-Apr 30, 2023
                                        </span>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>

                                <td>
                                    <div className='d-flex flex-column text-right'>
                                        <span>
                                            <strong>
                                                1,129
                                            </strong>
                                        </span>
                                        <span className='report_dates rate_incr'>
                                            2.1%
                                        </span>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td className='pro-tbl-name1'>
                                    <div className='d-flex flex-column'>
                                        <span>
                                            Compare to
                                        </span>
                                        <span className='report_dates'>
                                            Apr 1, 2023-Apr 30, 2023
                                        </span>
                                    </div>
                                </td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                            </tr>

                            <tr>
                                <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div>1</div>
                                        <div style={{ marginLeft: 10 }}>
                                            <img src={require('../Assets/pro-fb.png')} className='pro-img' />
                                        </div>
                                        <div className='pro-tbl-name'>Nike</div>
                                    </div>
                                </td>
                                <td >Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                            </tr>

                            <tr>
                                <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div>2</div>
                                        <div style={{ marginLeft: 10 }}>
                                            <img src={require('../Assets/pro-twitter.png')} className='pro-img' />
                                        </div>
                                        <div className='pro-tbl-name'>Nike</div>
                                    </div>
                                </td>
                                <td >Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                            </tr>

                            <tr>
                                <td>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div>3</div>
                                        <div style={{ marginLeft: 10 }}>
                                            <img src={require('../Assets/pro-insta.png')} className='pro-img' />
                                        </div>
                                        <div className='pro-tbl-name'>Nike</div>
                                    </div>
                                </td>
                                <td >Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                                <td>50</td>
                                <td>Smith</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </div>

        </>
    )
}
