import React, { useEffect, useState } from 'react'
import {
    FacebookShareButton,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookIcon,
    EmailShareButton,
    EmailIcon,
    TelegramShareButton,
    TelegramIcon,

} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { Modal, message } from 'antd';
import AWS from 'aws-sdk'

export default function MenuList({ Data }) {
    const [showMenu, setShowMenu] = useState(false);
    const [shareUrl, setShareUrl] = useState();
    const [shareModal, setShareModal] = useState(false);


    const ShareURL = (shareurl) => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        axios.post(`${process.env.REACT_APP_BACKEND_API}/library/encrypt`, {
            url: "https://oolook-assets-libraries.s3.amazonaws.com/" + (shareurl).replace(" ", "%20")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setShareUrl("http://app.oolook.in/Preview?" + res.data.encryptedUrl)
                setShareModal(true);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const StarredFile = () => {
        axios.post(`${process.env.REACT_APP_BACKEND_API}/library/update-status`, {
            url: `https://oolook-assets-libraries.s3.amazonaws.com/${Data.Key}`,
            newStatus: 'starred'
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const Copyfile = () => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-assets-libraries',
            // Key: '6620d6eb2f41333b29cab459/'
            MaxKeys: 100,
            Delimiter: '/',
        };

        s3.copyObject(params, function (err, data) {
            if (err) {
                console.error('Error fetching file from S3:', err);
                return;
            }
            console.log(data.Contents)
        })
    }

    return (
        <div>
            <button className='share_btn' onClick={() => { setShowMenu(!showMenu); }}>
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>

            {
                showMenu
                    ?
                    <div className='assets_menu_list'>
                        <ul className='assets_menu_list_section'>
                            <li>
                                <button className='d-flex align-items-center btn' onClick={() => { ShareURL(Data.Key) }}>
                                    <i class="fa fa-share-alt mx-1" aria-hidden="true"></i>
                                    Share
                                </button>
                            </li>

                            <li>
                                <button className='d-flex align-items-center btn'>
                                    <i class="fa fa-trash mx-1" aria-hidden="true"></i>
                                    Delete
                                </button>
                            </li>

                            <li>
                                <button className='d-flex align-items-center btn' onClick={() => { StarredFile() }}>
                                    <i class="fa fa-star mx-1" aria-hidden="true"></i>
                                    Starred
                                </button>
                            </li>
                        </ul>
                    </div>
                    :
                    null
            }

            <Modal
                open={shareModal}
                title=""
                footer={null}
                onCancel={() => { setShareModal(false) }}
            >
                <div className='d-flex align-items-center justify-content-center'>
                    <div className='m-2'>
                        <FacebookShareButton
                            url={shareUrl}
                        >
                            <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                    </div>

                    <div className='m-2'>
                        <WhatsappShareButton
                            url={shareUrl}
                        >
                            <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                    </div>

                    <div className='m-2'>
                        <EmailShareButton
                            url={shareUrl}
                        >
                            <EmailIcon size={40} round={true} />
                        </EmailShareButton>
                    </div>

                    <div className='m-2'>
                        <TelegramShareButton
                            url={shareUrl}
                        >
                            <TelegramIcon size={40} round={true} />
                        </TelegramShareButton>
                    </div>

                    <div className='m-2'>
                        <CopyToClipboard
                            onCopy={() => { message.success("Copied") }}
                            text={shareUrl}
                        >
                            <i class="fa fa-copy fa-2x"></i>
                        </CopyToClipboard>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
