import React, { useEffect } from 'react'
import axios from 'axios';


export default function Instagram() {

    const AddtoGroup = (pageID) => {
        var Pages = [];
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        pageID.map((elem) => {
            Pages.push({
                platform: "INSTAGRAM",
                platform_id: elem.page_details[0].accountId,
                profile: ''
            })
        })


        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: localStorage.getItem("selected_group"),
            connectedAccounts: Pages
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                window.close()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        console.log(token);

        axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/auth/callback`, {
            url: window.location.href
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data)
                AddtoGroup(res.data.result)
                // window.close()
            })
            .catch((err) => {
                console.log(err)
                // window.close()
            })
    }, [])

    return (
        <div>Redirecting.......</div>
    )
}
