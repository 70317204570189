import React, { useEffect, useState } from 'react'
import ChartSection from './ChartSection';
import Overview from './Reports Pages/Overview';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { ChangeSinceDate } from './store/Slices/DateSlice';
import { ChangeUntilDate } from './store/Slices/UntilDateSlice';
import { StoreSideBar } from './store/Slices/SIdeSlice';
import axios from 'axios';
import Comp_profile from './Comp_profile';
import Reportprofile from './Reportprofile';
import Images from '../Comman Components/Images';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';
import { FilterRedu } from './store/Slices/FilterSlice';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/DD/MM';

export default function ProfileReports() {
    const [profileTab, setProfileTab] = useState("Overview");
    const [until, setUntil] = useState();
    const [since, setSince] = useState();

    const [showFilter, setshowFilter] = useState(false);

    const [SourceFilter, setSourceFilter] = useState(false);

    const [avalibaleProfile, setAvaliableProifle] = useState([]);

    const [SelectedPlatform, setSelectedPlatform] = useState(null);

    const dispatch = useDispatch();

    const MonthsString = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]


    const d = new Date();
    var months = (d.getMonth() + 1).toString().padStart(2, "0");
    var day = d.getDate().toString().padStart(2, "0");

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    // Get Group Details
    const GetGroups = () => {
        var token;
        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/get-particular`, {
            groupName: localStorage.getItem("oolook_default_group")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setAvaliableProifle(res.data.group.connected_accounts);

                var selePlatform;
                var copyPlatforms;

                selePlatform = (res.data.group.connected_accounts).slice(0);
                copyPlatforms = (res.data.group.connected_accounts).slice(0);

                setSelectedPlatform(selePlatform);

                dispatch(FilterRedu(copyPlatforms));

            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Check Platform in a list or not
    const CheckPlatform = (value) => {
        if (value.platform == "FACEBOOK") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_pageId == value.platform_pageId) {
                    return true;
                }
            }
        }
        else if (value.platform == "INSTAGRAM") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id == value.platform_id) {
                    return true;
                }
            }
        }
        else if (value.platform == "PINTEREST") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id == value.platform_id) {
                    return true;
                }
            }
        }
    }

    // Push Data to selected array
    const ChangePaltform = (value, checked) => {
        var newCopy;
        if (checked) {
            SelectedPlatform.push(value);
        }
        else {
            if (value.platform == "FACEBOOK") {
                SelectedPlatform.map(item => {
                    if (item.platform_pageId === value.platform_pageId) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        // console.log(item.platform_id);
                    }
                })
            }
            if (value.platform == "INSTAGRAM") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                    }
                })
            }
            if (value.platform == "PINTEREST") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                    }
                })
            }
        }

        newCopy = (SelectedPlatform).slice(0);


        dispatch(FilterRedu(newCopy))
    }

    useEffect(() => {
        GetGroups()
        dispatch(StoreSideBar("Report"))
    }, [])

    return (
        <div>
            <div className='approval_header performace_header'>
                <div className='performance_inner'>
                    <div className='performace_subhead align-items-center'>
                        <div>
                            <h4>Profile Performance</h4>
                        </div>
                        <div className='mx-2'>
                            <i class="fa fa-star fa-1x" aria-hidden="true"></i>
                        </div>
                    </div>

                    <span className='date_txt'>Activity from {MonthsString[new Date(Sinceselector[0] * 1000).getMonth()] + " " + new Date(Sinceselector[0] * 1000).getDate() + ", " + new Date(Sinceselector[0] * 1000).getFullYear()} -
                        {MonthsString[new Date(untilSelector[0] * 1000).getMonth()] + " " + new Date(untilSelector[0] * 1000).getDate() + ", " + new Date(untilSelector[0] * 1000).getFullYear()}
                        <label className='highlight_txt'> {" "} ( multiple</label> time zones)</span>
                </div>

                <div className='d-flex align-items-center'>
                    <div>
                        <span className='Inbox_remaining performace_date'>
                            {/* <i class="fa fa-calendar" style={{ marginRight: '5px' }} aria-hidden="true"></i>
                            4/1/2023 – 4/30/2023

                            <label>
                                3/1/2023
                            </label>

                            <label>
                                3/31/2023
                            </label> */}

                            <RangePicker
                                format={dateFormat}
                                style={{ backgroundColor: 'transparent', border: 'none' }}
                                defaultValue={[dayjs(d.getFullYear() + "/" + '01' + "/" + months, dateFormat), dayjs(d.getFullYear() + "/" + day + "/" + months, dateFormat)]}
                                onChange={(e) => { dispatch(ChangeSinceDate(Date.parse(e[0].$d) / 1000)); dispatch(ChangeUntilDate(Date.parse(e[1].$d) / 1000)); }} />

                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-ellipsis-h"></i>
                        </span>
                    </div>

                    <div>
                        <span className='Inbox_remaining'>
                            <i class="fa fa-upload" style={{ marginRight: '5px' }}></i>
                            Export
                        </span>
                    </div>

                    <div>
                        <button className='Inbox_remaining Filter_btn' onClick={() => { setshowFilter(!showFilter) }}>
                            <img src={require('../Assets/Filter.png')} />
                            Filters
                        </button>
                    </div>
                </div>


            </div>

            {
                showFilter
                    ?
                    <>
                        <div className='w-25'>
                            {/* <div id="overlaysidebar" onClick={() => { setSourceFilter(false) }}></div> */}
                            <button className='filter_head' onClick={() => { setSourceFilter(!SourceFilter) }}>
                                <span>
                                    Source
                                </span>

                                <span>
                                    {
                                        SourceFilter
                                            ?
                                            <i class="fa fa-angle-up"></i>
                                            :
                                            <i class="fa fa-angle-down"></i>
                                    }
                                </span>
                            </button>

                            <div className='platform_name_header'>View All</div>
                            {
                                SourceFilter
                                    ?
                                    <div className='filter_list_section'>
                                        <div className='p-2 filter_list_head d-flex justify-content-between'>
                                            Source
                                            <button className='close_btn' onClick={() => { setSourceFilter(false) }}>
                                                Close
                                            </button>
                                        </div>

                                        <div>
                                            <ul className='Filter_list'>
                                                {
                                                    avalibaleProfile != null
                                                        ?
                                                        (avalibaleProfile.sort((a, b) => a.platform.localeCompare(b.platform))).map((elem) => {
                                                            return (
                                                                <li>
                                                                    <div className='d-flex align-items-center justify-content-between pages_list_view'>
                                                                        <div className='d-flex align-items-center w-100'>

                                                                            <div className='mx-2'>
                                                                                <input type="checkbox" defaultChecked={CheckPlatform(elem)} onChange={(e) => { ChangePaltform(elem, e.target.checked); }} />
                                                                            </div>

                                                                            {
                                                                                elem.platform == "FACEBOOK"
                                                                                    ?
                                                                                    <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='profile_img_post' />
                                                                                    :
                                                                                    elem.platform == "INSTAGRAM"
                                                                                        ?
                                                                                        <>
                                                                                            {<Images Id={elem.platform_id} />}
                                                                                        </>
                                                                                        :
                                                                                        <PinImage Id={elem.platform_id} />
                                                                            }

                                                                            {/* <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='profile_img_post' /> */}
                                                                            {
                                                                                elem.platform == "FACEBOOK"
                                                                                    ?
                                                                                    <i class="fa-brands fa-facebook mx-2" style={{ color: '#1877F2' }}></i>
                                                                                    :
                                                                                    elem.platform == "INSTAGRAM"
                                                                                        ?
                                                                                        <i class="fa-brands fa-instagram mx-2" style={{ color: '#E1306C' }}></i>
                                                                                        :
                                                                                        elem.platform == "PINTEREST"
                                                                                            ?
                                                                                            <i class="fa-brands fa-pinterest mx-2" style={{ color: '#E60023' }}></i>
                                                                                            :
                                                                                            null
                                                                            }
                                                                            {
                                                                                elem.platform == "FACEBOOK"
                                                                                    ?
                                                                                    <>
                                                                                        {elem.profile}
                                                                                    </>
                                                                                    :
                                                                                    "@UserName"
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                        :
                                                        null
                                                }
                                            </ul>
                                        </div>

                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </>
                    :
                    null
            }



            <div className='d-flex w-100'>
                <button className={profileTab == "Overview" ? 'w-50 tab_btn active_tab' : "w-50 tab_btn active_btn"} onClick={() => { setProfileTab("Overview") }}>
                    Overview
                </button>

                <button className={profileTab == "Profiles" ? 'w-50 tab_btn active_tab' : "w-50 tab_btn active_btn"} onClick={() => { setProfileTab("Profiles") }}>
                    Profiles
                </button>
            </div>

            {
                profileTab == "Overview"
                    ?
                    <Overview />
                    :
                    <Reportprofile />
            }
        </div>
    )
}
