import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function Tagmenu({ CommentData }) {
    const [searchTag, setSearchTag] = useState("");
    const [Tags, setTags] = useState(null);

    const getTags = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/tag/get-tagname`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setTags(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const Addtag = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/tag/create`, {
            tag_name: searchTag
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                message.success("New Tag Created");
                getTags()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const AddCommenttoTag = (checked, Tag_selected) => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        if (checked == true) {
            axios.patch(`${process.env.REACT_APP_BACKEND_API}/tag/add-comment`, {
                tag_name: Tag_selected,
                comments: [{
                    "comment_id":CommentData.post_id,
                    "text":CommentData.comments,
                    "date":CommentData.time,
                    "post_url" : CommentData.post_url,
                    "platform" : CommentData.platform,
                    "username" : CommentData.user_name
                }]
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        getTags();
    }, [])

    return (
        <div className='tag_modal'>
            <div className='tag_header w-100 d-flex justify-content-between align-item-center'>
                <span>
                    Tags
                </span>

                <div>
                    <button className='tag_remove_btn'>
                        Remove All
                    </button>
                </div>
            </div>

            <div className='tag_modal_body'>

                <div className='w-100'>
                    <input type="text" onChange={(e) => { setSearchTag(e.target.value) }} className='w-100 tag_search_fields' />
                </div>

                <div className='mt-3'>
                    {
                        Tags != null
                            ?
                            Tags.map((elem) => {
                                return (
                                    <div className='my-2'>
                                        <input type="checkbox" className='mx-2' onClick={(e) => {AddCommenttoTag(e.target.checked,elem)}} />
                                        {elem}
                                    </div>
                                )
                            })
                            :
                            null
                    }
                </div>
            </div>

            <div className='tag_footer'>
                <button className='add_tag_btn' onClick={() => { Addtag() }}>
                    <i class="fa fa-plus fa-sm" aria-hidden="true"></i>
                    {" " + searchTag + " "}
                    Tags
                </button>
            </div>
        </div>
    )
}
