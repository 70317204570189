import React, { useState, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files";
import FontPicker from '@aw-web-design/font-picker-react';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { io } from 'socket.io-client';
import { message, Modal, Progress, Spin } from 'antd';
import AWS from 'aws-sdk';

import MenuList from './MenuList';

export default function AssetsUpload() {
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const fileTypes = ["JPG", "PNG", "GIF", 'MP4'];

    const [showType, setShowType] = useState('file');
    const [ViewType, setViewType] = useState('grid');
    const [fonts, setFonts] = useState();
    const [userDetail, setUserDetails] = useState([]);
    const [isLoader, setIsLoader] = useState(true);
    const [progess, setProgess] = useState(0);
    const [photoPreview, setPhotoPreview] = useState();
    const [editFont, setEditFont] = useState(false);
    const [newFont, setNewFont] = useState('');
    const [UploadedAssets, setUploadedAssets] = useState([]);
    const [fileType, setFileType] = useState();
    const [FolderModal, setFolderModal] = useState(false);
    const [FolderName, setFolderName] = useState();
    const [Folder, setFolder] = useState(null);
    const [APIwait, setAPIwait] = useState(false);
    const [SelectedFolder, setSelectedFolder] = useState();
    const [localFiles, setFiles] = useState(null);
    const [RootFolders, setRootFolder] = useState();
    const [subFolderModal, setSubFolderModal] = useState(false);
    const [subFolder, setSubFolder] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [openLogo, setOpenLogo] = useState(false);

    // Getting onboardind details
    const GetonBoardingDetails = async () => {
        setIsLoader(false)
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_API}/onboarding/get-onboarding`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setUserDetails(res.data.OnBoarding)
                setNewFont(res.data.OnBoarding[0].brand_font)
                setIsLoader(true)
            })
            .catch((err) => {
                setIsLoader(true)
                console.log(err)
            })
    }

    // drag and drop function
    const dragNdrop = () => {
        return (
            <div className='d-flex flex-column align-items-center justify-content-center drag_box px-5 py-3'>
                <img src={require('../../Assets/folder_open.png')} className='folder_img' />
                <div className='mt-3'>
                    <label className='drag_head_name'>
                        Drag and Drop your files here
                    </label>
                </div>

                <label className='supported_file_text'>
                    Files supported PNG, JPG..
                </label>

                <div>
                    <button className='browse_btn p-2'>
                        <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                        Browse
                    </button>
                </div>
            </div>
        )
    }

    // Logo Drag and drop function
        const LogodragNdrop = () => {
            return (
                <div className='d-flex flex-column align-items-center justify-content-center logo_drag_box'>
                    <img src={userDetail[0].brand_logo} className='brand_logo_img' />    
    
                    <div>
                        <button className='browse_btn p-2'>
                            <i className="fa fa-plus mx-1" aria-hidden="true"></i>
                            Browse
                        </button>
                    </div>
                </div>
            )
        }

    // Upload Image to s3
    const uploadImage = async (e) => {
        if (e['type'].split('/')[0] == "image") {
            setFileType('photo')
        }
        else {
            setFileType('video')
        }
        // setLoader(true);
        let filename;
        const file = e;
        if (file == undefined) {
            message.warning("Upload a Video");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'assets-libraries',
            Key: SelectedFolder + filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            setProgess(percentageProgress);

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                message.error("Please check file upload JPG or MP4")
            }
            else {
                UploadAsstes(data.Location);
                GetFiles(SelectedFolder)
                // setLoader(false);
                // setSelectedFile(null); // Clear selected file after successful upload
            }
        });

        try {
            await upload.promise()

        } catch (error) {
            console.error('Upload failed', error);
        }


    }

    // Update Onboarding Details
    const updateonboarding = async () => {

        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding3`, {
            "brand_logo": userDetail[0].brand_logo,
            "brand_photos": userDetail[0].brand_photos,
            "brand_videos": userDetail[0].brand_videos,
            "brand_color": userDetail[0].brand_color,
            "brand_font": newFont.charAt(0).toUpperCase() +
                newFont.slice(1),
            "unique_id": userDetail[0].unique_id
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res)
                setEditFont(false)
                GetonBoardingDetails()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // upload brand logo
    const BrandLogouploadStocket = async (e) => {
        let filename;
        const file = e;
        if (file == undefined) {
            message.warning("Upload a File");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-assets-libraries',
            Key: filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            setProgess(percentageProgress)

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('Failed to upload file.');
            }
            else {
                // setLogoPeview(data.Location)
                userDetail.map((elem) => {
                    elem.brand_logo = data.Location
                })
            }
        });

        try {
            await upload.promise().then(() => {
                message.success(`File Uploaded`);
            })

        } catch (error) {
            console.error('Upload failed', error);
        }


    }

    // Upload Assets In Database
    const UploadAsstes = (newURL) => {

        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/library/add-assets`, {
            library: [{
                "url": newURL,
                "status": "recent"  // Optional, defaults to "recent"
            }]
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res);
                message.success("File Uploaded");
                // GetAssets();
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Getting all assets in database
    const GetAssets = async () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_API}/library/get-assets`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setUploadedAssets(res.data.AssetAndLibrary[0].library)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // API Create Folder
    const createFolder = () => {
        setAPIwait(true)
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/library/create-folders`, {
            // "folderName": 'New Folder',
            "parentFolder": FolderName
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res)
                setFolderModal(false)
                setAPIwait(false)
                GetAllFolder();
            })
            .catch((err) => {
                setAPIwait(false)
                console.log(err)
            })
    }

    // API Create Sub Folder
    const CreateSubFolder = () => {
        setAPIwait(true)
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        if (SelectedFolder.split(RootFolders)[1].split("/").length == 2) {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/library/create-folders`, {
                "folderName": FolderName,
                "parentFolder": SelectedFolder.split(RootFolders)[1].split("/")[0]
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    setFolderModal(false)
                    setAPIwait(false)
                    GetAllFolder();
                    setSubFolder(false);
                    message.success("Sub Folder Created");
                })
                .catch((err) => {
                    setAPIwait(false)
                    console.log(err)
                })
        }
        else {
            axios.post(`${process.env.REACT_APP_BACKEND_API}/library/create-folders`, {
                "folderName": FolderName,
                "parentFolder": SelectedFolder.split(RootFolders)[1]
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    setFolderModal(false)
                    setAPIwait(false)
                    GetAllFolder();
                    setSubFolder(false);
                    message.success("Sub Folder Created");
                })
                .catch((err) => {
                    setAPIwait(false)
                    console.log(err)
                })
        }
    }

    // Get Files From AWS s3 direct
    const GetFiles = (file) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-assets-libraries',
            // Key: '6620d6eb2f41333b29cab459/'
            MaxKeys: 100,
            Delimiter: '/',
            Prefix: file
        };

        s3.listObjectsV2(params, function (err, data) {
            if (err) {
                console.error('Error fetching file from S3:', err);
                return;
            }
            setFiles(data.Contents);
        })
    }

    // Getting Root Folder File Direct
    const RootFolder = (file) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-assets-libraries',
            // Key: '6620d6eb2f41333b29cab459/',
            MaxKeys: 100,
            Delimiter: "/",
            Prefix: file // 6620d6eb2f41333b29cab459/New Folder/ formate
        };

        s3.listObjectsV2(params, function (err, data) {
            if (err) {
                console.error('Error fetching file from S3:', err);
                return;
            }
            console.log(data.Contents)
            setFiles(data.Contents);
        })
    }

    // Get Folder from API
    const GetAllFolder = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/library/get-folders`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data.subfolders)
                setFolder(res.data.subfolders)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get the Profile from API
    const Profile = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/user/profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                GetFiles(res.data.user._id)
                setSelectedFolder(res.data.user._id + "/")
                setRootFolder(res.data.user._id + "/")
                RootFolder(res.data.user._id + "/")
            })
            .catch((err) => {
                console.log(err)
            })
    }


    // Get the Sub Folder from AWS s3
    const GetSubFolders = (foldername) => {
        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-assets-libraries',
            // Key: foldername,
            // MaxKeys: 2,
            // Delimiter: "",
            Prefix: foldername //6620d6eb2f41333b29cab459/New Folder/ formate
        };


        s3.listObjectsV2(params, function (err, data) {
            if (err) {
                console.error('Error fetching file from S3:', err);
                return;
            }
            console.log("sub", (data.Contents))
            setSubFolder(data.Contents)
        })
    }

    useEffect(() => {
        Profile()
        GetonBoardingDetails()
        GetAllFolder();
        // GetFiles()
    }, [])

    return (
        <div>
            {
                isLoader == false
                &&
                <div id='overlay'>
                    <img src={require('../../Assets/logo_overlay.png')} />
                </div>
            }


            <div className='d-flex align-items-center justify-content-between my-2 mx-3'>
                <div>
                    <label className='section_heading'>
                        My Assets Libary
                    </label>
                </div>

                <div className='w-50'>
                    {
                        progess > 0
                            ?
                            <Progress percent={progess} />
                            :
                            null
                    }
                </div>

                <div className='d-flex align-items-center'>
                    <div>
                        <div className='d-flex assets_toggle'>
                            <button className='assets_toogle_btn' onClick={() => { setShowType('file') }}>
                                <i className="fa fa-file-text mx-1" aria-hidden="true"></i>
                                Files
                            </button>

                            <button className='assets_toogle_btn' onClick={() => { setShowType('folder'); }}>
                                <i className="far fa-folder mx-1"></i>
                                Folders
                            </button>
                        </div>
                    </div>


                    <div className='mx-2'>
                        <div className='d-flex assets_toggle'>
                            <button className='view_toogle_btn' onClick={() => { setViewType('list') }}>
                                <i className={ViewType == "list" ? "fa-solid fa-bars active_view" : "fa-solid fa-bars"}></i>
                            </button>

                            <button className='view_toogle_btn' onClick={() => { setViewType('grid') }}>
                                {/* <img className={ViewType == "grid" ? "active_view" : ""} src={require('../../Assets/grid_view.png')} style={{width:'20px'}}/> */}
                                {/* <i class="fa-solid fa-grid"></i> */}
                                <i className={ViewType == "grid" ? "fas fa-grip-horizontal active_view" : "fas fa-grip-horizontal"} aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                </div>

            </div>

            <div className='d-flex justify-content-between mt-3'>
                <div style={{ width: '32%' }}>
                    <FileUploader children={dragNdrop()} handleChange={(e) => { uploadImage(e); }} name="file" types={fileTypes} />
                </div>


                <div className='color_font_section'>
                    <div className='font_style'>
                        {
                            editFont
                                ?
                                <div className='pt-2'>
                                    <FontPicker
                                        // activeFontFamily={userDetail[0].brand_font}
                                        apiKey="AIzaSyAub6MjUwcD5fmBd8zZpCzLbHoDfnsMJW8"
                                        onChange={(e) => setNewFont(e.family)}
                                    />
                                    <p className='apply-font userFonts' >
                                        {
                                            newFont
                                        }
                                    </p>
                                </div>
                                :
                                <>
                                    <div style={{ display: 'none' }}>
                                        {
                                            userDetail.length != 0
                                                ?
                                                <FontPicker
                                                    apiKey="AIzaSyAub6MjUwcD5fmBd8zZpCzLbHoDfnsMJW8"
                                                    activeFontFamily={userDetail[0].brand_font}
                                                />
                                                :
                                                null
                                        }
                                    </div>
                                    {
                                        userDetail.length != 0
                                            ?
                                            <p className='apply-font userFonts'>
                                                {
                                                    userDetail[0].brand_font
                                                }
                                            </p>
                                            :
                                            null
                                    }
                                </>
                        }
                    </div>

                    {
                        editFont
                            ?
                            <button className='update_btn' onClick={() => { updateonboarding() }}>
                                Update
                            </button>
                            :
                            <div className='edit_font_head' onClick={() => { setEditFont(true) }}>
                                <i class="fa fa-pencil mx-2" aria-hidden="true"></i>
                                Edit Color Palette & Fonts
                            </div>

                    }

                </div>

                <div className='logo_section'>
                    <label className='brand_head'>
                        Brand Name
                    </label>

                    {
                        openLogo
                            ?
                            <div style={{ width: '100%'}}>
                                <FileUploader children={LogodragNdrop()} handleChange={(e) => { BrandLogouploadStocket(e); }} name="file" types={["JPG", "PNG"]} />
                            </div>
                            :
                            <>
                                {
                                    userDetail.length != 0
                                        ?
                                        <img src={`${userDetail[0].brand_logo}`} className='brand_img' />
                                        :
                                        <img src={require('../../Assets/test_brand.png')} className='brand_img' />
                                }
                            </>
                    }


                    {
                        openLogo
                            ?
                            <button className='update_btn' onClick={() => { updateonboarding(); setOpenLogo(false) }}>
                                Update
                            </button>
                            :
                            <div className='edit_font_head' onClick={() => { setOpenLogo(true) }}>
                                <i class="fa fa-pencil mx-2" aria-hidden="true"></i>
                                Edit Logo & Company Info
                            </div>
                    }

                </div>
            </div>

            <div className='d-flex justify-content-end mt-2'>
                <div>
                    <button className='create_folder_btn' onClick={() => { setFolderModal(true) }}>
                        <i class="fa fa-plus mx-2" aria-hidden="true"></i>
                        Folder
                    </button>
                </div>
            </div>

            {/* Folders Lists */}
            <div className='d-flex align-items-center mt-4'>
                <div className='folder_section root_folder' onClick={() => { RootFolder(RootFolders); setSelectedFolder(RootFolders); }}>
                    /
                </div>
                {
                    Folder != null
                        ?
                        Folder.map((elem) => {
                            return (
                                <>
                                    <div className='folder_section' key={elem.Key} onClick={() => { setSelectedFolder(elem); GetFiles(elem); GetSubFolders(elem) }}>
                                        {
                                            elem.split("/")[1]
                                        }
                                    </div>
                                </>
                            )
                        })
                        :
                        null
                }
            </div>

            <div className='mt-3 d-flex justify-content-between align-items-center'>
                {
                    SelectedFolder != null
                        ?
                        SelectedFolder.split("/")[1]
                        :
                        null
                }


                <button className='sub_folder_btn' onClick={() => { setSubFolderModal(true) }}>
                    <i class="fa fa-plus mx-2" aria-hidden="true"></i>
                    {
                        SelectedFolder != null
                            ?
                            `Folder ${SelectedFolder.split("/")[1]}`
                            :
                            null
                    }
                </button>

            </div>

            <hr />

            {/* Sub Folder List */}
            <div className='d-flex'>
                {
                    subFolder != null
                        ?
                        subFolder.map((elem) => {
                            if ((elem.Key).split("/")[3] == '') {
                                return (
                                    <div className='folder_section' key={elem.Key} onClick={() => { setSelectedFolder(elem.Key); GetFiles(elem.Key); GetSubFolders(elem.Key) }}>
                                        {
                                            (elem.Key).split("/")[2]
                                        }
                                    </div>
                                )
                            }
                        })
                        :
                        null
                }
            </div>

            {/* Files List in the folder */}
            <div className='row mx-0 mt-5'>
                {
                    localFiles != null
                        ?
                        (localFiles.slice(1)).map((elem) => {
                            if ((elem.Key).split("/").slice(-1)[0].match(/\.(jpeg|jpg|gif|png)$/) != null) {
                                return (
                                    <div className='col-lg-3 assets_div'>
                                        <img src={`https://oolook-assets-libraries.s3.amazonaws.com/${elem.Key}`} className='uploaded_assets' />

                                        <div className='assets_menu'>
                                            <MenuList Data={elem} />
                                        </div>

                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div className='col-lg-3 assets_div mt-5'>
                                        <video controls src={`https://oolook-assets-libraries.s3.amazonaws.com/${elem.Key}`} className='uploaded_assets'>
                                        </video>

                                        <button className='share_btn'>
                                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                )
                            }
                        })
                        :
                        "Empty File"
                }
            </div>

            <Modal
                open={FolderModal}
                title="Create New Folder"
                footer={null}
                onCancel={() => { setFolderModal(false) }}
            >
                <div>
                    <div className='d-flex flex-column'>
                        <input type="text" placeholder='Enter Folder Name' onChange={(e) => { setFolderName(e.target.value) }} />
                        <button className='mt-2 create_folder_modal_btn' onClick={() => { createFolder() }}>
                            {
                                APIwait
                                    ?
                                    <Spin indicator={antIcon} />
                                    :
                                    "Create Folder"
                            }
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                open={subFolderModal}
                title="Create New Folder"
                footer={null}
                onCancel={() => { setSubFolderModal(false) }}
            >
                <div>
                    <div className='d-flex flex-column'>
                        <input type="text" placeholder='Enter Folder Name' onChange={(e) => { setFolderName(e.target.value) }} />
                        <button className='mt-2 create_folder_modal_btn' onClick={() => { CreateSubFolder() }}>
                            {
                                APIwait
                                    ?
                                    <Spin indicator={antIcon} />
                                    :
                                    "Create Sub Folder"
                            }
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
