import React, { useEffect, useState } from 'react'
import ChartSection from '../ChartSection';
import { Impressionchart } from '../Impressionchart';
import Engagementchart from './Engagementchart';

export default function Overview() {

    return (
        <div>
            <div className='summery_section'>
                <div className='summary_head_box'>
                    <h4>
                        Performance Summary
                    </h4>
                    <label>
                        View your key profile performance metrics from the reporting period.
                    </label>
                </div>

                <div>
                    <table className='table summary_table'>
                        <tr>
                            <td>
                                <div>
                                    <label className='summaryHead'>
                                        Followers
                                    </label>

                                    <div className='d-flex align-items-center mt-3'>
                                        <span className='total_numbers'>
                                            {/* {
                                                ProfileData
                                                    ?
                                                    ProfileData.followers_count
                                                    :
                                                    null
                                            } */}
                                            7777
                                        </span>

                                        <span className='differance_percentage'>
                                            <i class="fa fa-arrow-up"></i>
                                            36.2%
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div>
                                    <label className='summaryHead'>
                                        Impressions
                                    </label>

                                    <div className='d-flex align-items-center mt-3'>
                                        <span className='total_numbers'>
                                            7,256
                                            {/* {
                                                MediaData != null
                                                    ?
                                                    MediaData.data[0].values.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.value, 0
                                                    )
                                                    // MediaData.data[0].values.map((elem)=>{
                                                    //     console.log(elem.value)
                                                    // })
                                                    :
                                                    null
                                            } */}
                                        </span>

                                        <span className='differance_percentage'>
                                            <i class="fa fa-arrow-up"></i>
                                            36.2%
                                        </span>
                                    </div>
                                </div>

                            </td>

                            <td>
                                <div>
                                    <label className='summaryHead'>
                                        Engagements
                                    </label>

                                    <div className='d-flex align-items-center mt-3'>
                                        <span className='total_numbers'>
                                            {/* {
                                                EngagementData != null
                                                    ?
                                                    EngagementData.data[0].total_value.value
                                                    :
                                                    null
                                            } */}
                                            7787
                                        </span>

                                        <span className='differance_percentage'>
                                            <i class="fa fa-arrow-up"></i>
                                            36.2%
                                        </span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div>
                                    <label className='summaryHead'>
                                        Post Link Clicks
                                    </label>

                                    <div className='d-flex align-items-center mt-3'>
                                        <span className='total_numbers'>
                                            {/* {
                                                MediaData != null
                                                    ?
                                                    MediaData.data[5].values.reduce((accumulator, currentValue) =>
                                                        accumulator + currentValue.value, 0
                                                    )
                                                    // MediaData.data[0].values.map((elem)=>{
                                                    //     console.log(elem.value)
                                                    // })
                                                    :
                                                    null
                                            } */}

                                            32
                                        </span>

                                        <span className='differance_percentage'>
                                            <i class="fa fa-arrow-up"></i>
                                            36.2%
                                        </span>
                                    </div>
                                </div>
                            </td>

                            <td colSpan={2}>
                                <div>
                                    <label className='summaryHead'>
                                        Engagement Rate (per Impression)
                                    </label>

                                    <div className='d-flex align-items-center mt-3'>
                                        <span className='total_numbers'>
                                            7,256
                                            {/* {
                                                TotalLikes != null
                                                    ?
                                                    (parseFloat(TotalLikes.data[0].total_value.value / ProfileData.followers_count) * 100).toFixed(2)
                                                    :
                                                    null
                                            } */}
                                        </span>

                                        <span className='differance_percentage'>
                                            <i class="fa fa-arrow-up"></i>
                                            36.2%
                                        </span>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div className='mt-5'>
                {/* {
                    AudienceGrowths == 400
                        ?
                        
                        :
                        null
                } */}
                <ChartSection />

                <div className='mt-5'>
                    <Impressionchart />
                </div>

                <div className='mt-5'>
                    <Engagementchart />
                </div>
            </div>
        </div>
    )
}

