import React from 'react'

export default function Rejected() {
  return (
    <div>
    <div className='approval_header'>
        <h4>Need Approval</h4>
    </div>

    <table className='filter_table'>
        <tr>
            <td>
                <label>Draft Type</label>
                <select>
                    <option>Viewing all</option>
                </select>
            </td>

            <td>
                <label>Profiles</label>
                <select>
                    <option>Viewing all</option>
                </select>
            </td>

            <td>
                <label>Tags</label>
                <select>
                    <option>Viewing all</option>
                </select>
            </td>

            <td>
                <label>Authors</label>
                <select>
                    <option>Viewing all</option>
                </select>
            </td>
        </tr>
    </table>

    <div className='sort_menu'>
        <label>
            Sort by
        </label>

        <select>
            <option>Last Modified Newest</option>
        </select>
    </div>

    <div className='post_section' style={{borderColor:'#FF0000'}}>
        <div>
            <div className='post_header' style={{backgroundColor:'#FDCDCD'}}>
                <img src={require('../Assets/IMAGE.png')} className='post_header_img' />

                <div style={{ width: '70%' }}>
                    <div>
                        <i class="fa-brands fa-facebook"></i>
                        <label style={{marginLeft:'10px'}}>
                            Gold’s Gym Jaipur
                        </label>
                    </div>

                    <label className='status'>
                        Needs Approval
                    </label>
                </div>

                <div className='date_section'>
                    <label>
                        Sat, May 6, 2023 4:20 am
                    </label>
                </div>

            </div>
        </div>

        <div className='post_details'>
            <p>
                I’m thrilled to announce my biggest ever project — Motion Foundation: the Ultimate Beginner’s Guide to Motion Design is finally open for enrollment!Find out more at benmarriott.comLink in the bio 🙃Thanks so much to @qbsoundstudio for the incredible music and sound design! 🔊#motionfoundation #motiondesign #motiongraphics #motiongraphic #design #learnanimation #animationcourses
            </p>

            <div className='post_photo'>
                <img src={require('../Assets/Mask group.png')} />

                <img src={require('../Assets/Mask2.png')} />

                <img src={require('../Assets/Mask3.png')} />
            </div>
        </div>

        <div className='post_footer'>
            <img src={require('../Assets/Postfooter.png')} />

            <label>
                by Author/ Space Owl
            </label>
        </div>
    </div>
</div>
  )
}
