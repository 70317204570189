import { configureStore } from "@reduxjs/toolkit";
import { DateSlice } from "./Slices/DateSlice";
import { UntilDateSlice } from "./Slices/UntilDateSlice";
import { SideSlice } from "./Slices/SIdeSlice";
import { ProfileSlice } from "./Slices/ProfileSlice";
import {FilterSlice} from './Slices/FilterSlice'

const store=configureStore({
    reducer:{
        SinceDates:DateSlice.reducer,
        UntilDates:UntilDateSlice.reducer,
        SideBarName:SideSlice.reducer,
        Profiles:ProfileSlice.reducer,
        Filter:FilterSlice.reducer
    }
})

export default store
