import axios from 'axios'
import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux';
import { ProfileRedu } from './store/Slices/ProfileSlice';

export default function Header() {

    const [userData, setUserData] = useState(null);

    const ProfileSelector = useSelector((state) => {
        return state.Profiles
    })

    return (
        <div className='header d-flex'>
            <div className='welcome_section d-flex'>
                <img src={require('../Assets/Avtar.png')} className='avtar' />
                <div className='welcome_msg'>
                    <h4>Welcome, {" "}
                        {
                            ProfileSelector.length != 0
                                ?
                                ProfileSelector[0].first_name
                                :
                                null
                        }
                    </h4>
                </div>
            </div>


            <div className='d-flex'>
                <div className='trial_msg'>
                    7 days remaining in trial
                </div>
                <button className='feature_btn btn'>
                    <img src={require('../Assets/star.png')} />
                    Trial more features
                </button>

                <button className='subscribe_btn btn'>
                    Start my subscription
                </button>
            </div>
        </div>
    )
}
