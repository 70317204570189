import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function Comp_overview() {

    const [InstaGrowth, setInstagrwoth] = useState([]);
    const [FbGrowth, setFBGrowth] = useState([]);
    // const [YTsubscriber, setytSubscriber] = useState([]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                }
            }
        },
        elements: {
            point: {
                radius: 0
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    padding: 30
                }
            }
        }

    };

    const data = {
        datasets: [
            {
                label: 'Your Average', // Name the series
                data: FbGrowth, // Specify the data values array
                fill: true,
                borderColor: '#08C4B2', // Add custom color border (Line)
                backgroundColor: '#08C4B2', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            },
            {
                label: 'Competitor Average', // Name the series
                data: InstaGrowth, // Specify the data values array
                fill: true,
                borderColor: '#6F5ED3', // Add custom color border (Line)
                backgroundColor: '#6F5ED3', // Add custom color background (Points and Fill)
                borderWidth: 1, // Specify bar border width
                tension: 0.2
            },
            
        ],
    };
  return (
    <div>
        <div className='comp-box'>
            <div style={{fontSize: 14, fontWeight: "bold"}}>Summary</div>
            <div style={{fontSize: 12}}>Compare your profile performance to your competitors.</div>
        </div>

        <div className='com-div'>
            <div className='comp-box2'>
                <div style={{fontSize: 14, fontWeight: "bold", textDecoration:"underline", marginTop: 10 }}>Audience Average</div>
                <div style={{flexDirection: 'row', display: "flex", marginTop: 20 }}>
                    <div className='comp-text1'>Your Average</div>
                    <div className='comp-text21'>149.75</div>
                </div>
                <div style={{flexDirection: 'row', display: "flex", marginTop: 10 }}>
                    <div className='comp-text1'>Competitor Average</div>
                    <div className='comp-text22'>8,497,666.71</div>
                </div>
            </div>

            <div className='comp-box2'>
                <div style={{fontSize: 14, fontWeight: "bold", textDecoration:"underline", marginTop: 10 }}>Public Engagements Average</div>
                <div style={{flexDirection: 'row', display: "flex", marginTop: 20 }}>
                    <div className='comp-text1'>Your Average</div>
                    <div className='comp-text21'>17.13</div>
                </div>
                <div style={{flexDirection: 'row', display: "flex", marginTop: 10 }}>
                    <div className='comp-text1'>Competitor Average</div>
                    <div className='comp-text22'>8,497,666.71</div>
                </div>
            </div>

            <div className='comp-box2'>
                <div style={{fontSize: 14, fontWeight: "bold", textDecoration:"underline", marginTop: 10 }}>Public Engagements per Post</div>
                <div style={{flexDirection: 'row', display: "flex" , marginTop: 20}}>
                    <div className='comp-text1'>Your Average</div>
                    <div className='comp-text21'>0.21</div>
                </div>
                <div style={{flexDirection: 'row', display: "flex", marginTop: 10 }}>
                    <div className='comp-text1'>Competitor Average</div>
                    <div className='comp-text22'>8,497,666.71</div>
                </div>
            </div>
        </div>

        {/* audience Growth  */}
        <div className='chart_section'>
            <div className='chart_heading d-flex flex-column px-3 py-3'>
                <label className='label_head'>
                    Audience Growth
                </label>

                <label className='label_sub_head'>
                    See how your audience grew during the reporting period.
                </label>
            </div>

            <div style={{marginLeft:20, fontSize: 12, marginTop: 20}}>Net Audience Growth Average</div>
            
            <div style={{ height: '40vh', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
                <Line options={options} data={data} />
            </div>
        

            <table className='table-compbox'>
                <tr>
                    <th className='comp-th1'>Twitter Followers</th>
                    <th className='comp-th2'>Followers</th>
                    <th className='comp-th2'>Net Follower Growth</th>
                    <th className='comp-th2'>% Follower Growth</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                    <td className='comp-th4'>4.00</td>
                    <td className='comp-th4'>3.62%</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                    <td className='comp-th4'>5,169.00</td>
                    <td className='comp-th4'>0.04%</td>
                </tr>

                <div className='comp-center'>Followers</div>

                
                <tr>
                    <th className='comp-th1'>Facebook Followers</th>
                    <th className='comp-th2'>Followers</th>
                    <th className='comp-th2'>Net Follower Growth</th>
                    <th className='comp-th2'>% Follower Growth</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                    <td className='comp-th4'>4.00</td>
                    <td className='comp-th4'>3.62%</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                    <td className='comp-th4'>5,169.00</td>
                    <td className='comp-th4'>0.04%</td>
                </tr>

                <div className='comp-center'>Followers</div>
                
                

                <tr>
                    <th className='comp-th1'>Instagram Followers</th>
                    <th className='comp-th2'>Followers</th>
                    <th className='comp-th2'>Net Follower Growth</th>
                    <th className='comp-th2'>% Follower Growth</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                    <td className='comp-th4'>4.00</td>
                    <td className='comp-th4'>3.62%</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                    <td className='comp-th4'>5,169.00</td>
                    <td className='comp-th4'>0.04%</td>
                </tr>

                <div className='comp-center'>Followers</div>
                
            </table>


            

                

           
        </div>

        {/* Publishing behaviour  */}
        <div className='chart_section'>
            <div className='chart_heading d-flex flex-column px-3 py-3'>
                <label className='label_head'>
                    Publishing Behavior
                </label>

                <label className='label_sub_head'>
                    View the different types of posts you and your competitors published during the selected time period.
                </label>
            </div>

            <div style={{marginLeft:20, fontSize: 12, marginTop: 20}}>Published Posts Average</div>
            
            <div style={{ height: '40vh', paddingLeft: '30px', paddingRight: '30px', paddingTop: '40px' }}>
                {
                    console.log(data)
                }
                <Line options={options} data={data} />
            </div>
        

            <table className='table-compbox'>
                <tr>
                    <th className='comp-th1'>Twitter Published Posts</th>
                    <th className='comp-th2'>Published Twitter</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                </tr>

                <div className='comp-center'>Followers</div>

                
                <tr>
                    <th className='comp-th1'>Facebook Published Posts</th>
                    <th className='comp-th2'>Published Posts</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                </tr>

                <div className='comp-center'>Followers</div>
                
                <tr>
                    <th className='comp-th1'>Instagram Published Posts & Reels</th>
                    <th className='comp-th2'>Published Posts & Reels</th>
                </tr>
                <tr>
                    <td className='comp-th3'>Your Average</td>
                    <td className='comp-th4'>114.40</td>
                </tr>
                <tr>
                    <td className='comp-th3'>Competitor Average</td>
                    <td className='comp-th4'>12,713,268.57</td>
                </tr>

                <div className='comp-center'>Followers</div>
                
            </table>
        </div>
    </div>
  )
}
