import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Images from '../Comman Components/Images';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';

export default function Approval() {
    const location = useLocation();
    const navigate = useNavigate();

    const Post = (data) => {
        navigate('/Dashboard/PublishPost', { state: { PublishData: data } })
    }

    useEffect(() => {
        console.log((location.state))
    }, [])
    return (
        <div>
            <div className='approval_header'>
                <h4>Need Approval</h4>
            </div>

            <table className='filter_table'>
                <tr>
                    <td>
                        <label>Draft Type</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Profiles</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Tags</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Authors</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>
                </tr>
            </table>

            <div className='sort_menu'>
                <label>
                    Sort by
                </label>

                <select>
                    <option>Last Modified Newest</option>
                </select>
            </div>

            {
                location.state.genData.PostedData.map((elem) => {
                    return (
                        <div className='post_section'>
                            <div>
                                <div className='post_header align-items-center'>
                                    <div className='d-flex align-items-center w-75'>
                                        <div>
                                            {
                                                elem.platform == "FACEBOOK"
                                                    ?
                                                    <img src={`https://graph.facebook.com/${elem.user_id}/picture?return_ssl_resources=true`} className='filter_profile_img' />
                                                    :
                                                    elem.platform == "INSTAGRAM"
                                                        ?
                                                        <>
                                                            {<Images Id={elem.platform_id} />}
                                                        </>
                                                        :
                                                        <PinImage Id={elem.platform_id} />
                                            }
                                        </div>

                                        <div style={{ width: '70%',marginLeft:'20px' }}>
                                            {
                                                location.state != null
                                                    ?
                                                    <div>
                                                        {
                                                            location.state.genData.platform == "INSTAGRAM"
                                                                ?
                                                                <i class="fa-brands fa-instagram"></i>
                                                                :
                                                                null

                                                        }

                                                        {
                                                            location.state.genData.platform == "FACEBOOK"
                                                                ?
                                                                <i class="fa-brands fa-facebook"></i>
                                                                :
                                                                null

                                                        }
                                                        <label style={{ marginLeft: '10px' }}>
                                                            Gold’s Gym Jaipur
                                                        </label>
                                                    </div>
                                                    :
                                                    null

                                            }

                                            <label className='status'>
                                                Needs Approval
                                            </label>
                                        </div>
                                    </div>

                                    <div className='date_section'>
                                        <label>
                                            {((elem).date).split("T")[0]}
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className='post_details'>
                                <p>
                                    {elem.message}
                                </p>

                                <div className='post_photo'>
                                    {
                                        location.state != null
                                            ?
                                            // <img src={require('../Assets/Mask2.png')} />
                                            <img src={elem.image_url[0]} />
                                            :
                                            null
                                    }
                                    {/* <img src={require('../Assets/Mask group.png')} />

                        <img src={require('../Assets/Mask2.png')} />

                        <img src={require('../Assets/Mask3.png')} /> */}
                                </div>
                            </div>

                            <div className='post_footer d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <img src={require('../Assets/Postfooter.png')} />

                                    <label>
                                        by Author/ Space Owl
                                    </label>
                                </div>

                                <div>
                                    <button className='btn' onClick={() => { Post(location.state.genData) }}>
                                        <i class="fa-solid fa-share"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }


        </div>
    )
}
