import React, { useEffect } from 'react'
import axios from 'axios';


export default function Pinterest() {

    const AddtoGroup = (pageID) => {
        var Pages = [];
        var token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }


        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: localStorage.getItem("oolook_default_group"),
            connectedAccounts: [{
                platform: "PINTEREST",
                platform_id: pageID,
                profile: ''
            }]
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((res) => {
            window.close()
        })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const getQueryParam = (name) => {
            const params = new URLSearchParams(window.location.search);
            return params.get(name);
        };

        const codes = getQueryParam('code');

        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (codes) {
            axios.get(`${process.env.REACT_APP_BACKEND_API}/pinterest/auth/callback`, {
                params: {
                    code: codes,
                    state: 'state'
                },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then((res) => {
                    console.log(res)
                    AddtoGroup(res.data.user_id)
                    // window.close()
                })
                .catch((err) => {
                    console.log(err)
                    // window.close()
                })
        }
    }, [])

    return (
        <div>Redirecting.......</div>
    )
}
