import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import DummyPreview from './DummyPreview';
import { Carousel } from 'antd'

export default function InstagarmModal({ Previewimages, Previewcaptions, PostType }) {
    const [open, setOpen] = useState(true);
    const [Images, setImages] = useState()

    useEffect(() => {
        setImages(Previewimages)
    }, [Previewimages, PostType, Previewcaptions]);

    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    if (Previewimages.length == 0 && Previewcaptions == '') {
        return (
            <DummyPreview />
        )
    }
    else
        return (
            <div className='w-100 d-flex flex-column align-items-center social_preview_box my-3'>
                <button onClick={() => { setOpen(!open) }} className='my-3 toggle_btn d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center'>
                        <i class="fa-brands fa-instagram fa-lg mx-2" style={{ color: '#d62976  ' }}></i>
                        <label className='social_name'>
                            Instagram
                        </label>
                    </div>

                    <div>
                        <i className={open ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                    </div>
                </button>

                {
                    open
                        ?
                        <div className='facebook_preview'>
                            <div className='d-flex align-items-center p-2'>
                                <div>
                                    <img src={require('../../Assets/grp_profile.png')} />
                                </div>

                                <div className='mx-2'>
                                    <label className='userid_preview'>
                                        My Web-Space Owl
                                    </label>
                                </div>
                            </div>

                            {
                                Previewimages.length == 1
                                    ?
                                    <>
                                        {
                                            PostType === "photo"
                                                ?
                                                <div className='d-flex justify-content-center w-100 multiple_img'>
                                                    <div>
                                                        <img src={Previewimages[0]} className="previewImgs" />
                                                    </div>
                                                </div>
                                                :
                                                PostType == "video"
                                                    ?
                                                    <div className='d-flex justify-content-center'>
                                                        <video src={Previewimages[0]} controls className="previewImgs">
                                                        </video>
                                                    </div>
                                                    :
                                                    <Skeleton
                                                        width="100%"
                                                        height="50vh"
                                                        containerClassName="avatar-skeleton"
                                                    />
                                        }
                                    </>
                                    :
                                    <Carousel>
                                        {
                                            Previewimages.map((elem) => {
                                                if (elem.split('.').slice(-1) == "mp4") {
                                                    return (
                                                        <div>
                                                            <video src={elem} controls className="previewImgs">
                                                            </video>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <div>
                                                            <img src={elem} className="previewImgs" />
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </Carousel>
                            }



                            {
                                Previewcaptions === "Story message Disable"
                                    ?
                                    null
                                    :
                                    <>
                                        <div className='d-flex justify-content-left align-items-center p-4 px-2'>
                                            <div>
                                                <i class="fa-regular fa-heart fa-lg mx-2"></i>
                                            </div>

                                            <div className='d-flex'>
                                                <i class="fa-regular fa-comment fa-flip-horizontal fa-lg mx-2"></i>
                                            </div>
                                        </div>

                                        <div className='mx-2 preview_captions'>
                                            <label className='instagram_user_name'>
                                                kamal_pathak_0000
                                            </label>
                                            {
                                                Previewcaptions == ''
                                                    ?
                                                    <Skeleton width={70} />
                                                    :
                                                    Previewcaptions
                                            }
                                        </div>

                                        <div className='insta_preview_date'>
                                            5 Mar
                                        </div>
                                    </>
                            }


                        </div>
                        :
                        null
                }
            </div>
        )
}
