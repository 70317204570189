import { createSlice } from "@reduxjs/toolkit";

const FilterSlice=createSlice({
    name:"FilterSlice",
    initialState:[],
    reducers:{
        FilterRedu(state,action){
            state.length=0          
            state.push(action.payload)
        },
    }
})

export {FilterSlice}

export const {FilterRedu}=FilterSlice.actions