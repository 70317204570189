import React, { useEffect, useRef, useState } from 'react'
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import Steps from '../Steps';
import { useNavigate } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import FontPicker from '@aw-web-design/font-picker-react';
import axios from 'axios';
import { message, Spin, Progress } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AWS from 'aws-sdk';

export default function AssetsData() {

    const navigate = useNavigate();

    const [open, setopen] = useState(false);
    const [color, setColor] = useState(null);
    const [showFonts, setShowfonts] = useState();

    const LogoRef = useRef(null);
    const PhotoRef = useRef(null);
    const VideoRef = useRef(null);


    const [BrandLogo, setBrandLogo] = useState();
    const [Photo, setPhoto] = useState();
    const [video, setVideo] = useState();
    const [fonts, setFonts] = useState();
    const [fileType, setFileType] = useState();

    const [LogoPreview, setLogoPeview] = useState();
    const [PhotoPreview, setPhotoPreview] = useState();
    const [videoPreview, setvideoPreview] = useState();
    const [imgprogess, setProgess] = useState(0);
    const [videoprogess, setvideoProgess] = useState(0);
    const [LogoProgess, setLogoProgess] = useState(0);

    const [uniqueID, setUniqueID] = useState();

    const [isLoader, setIsLoader] = useState(false);

    const openBox = () => {
        setopen(!open);
    }

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;

    const uploadAssets = async () => {
        setIsLoader(true);

        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding3`, {
            "brand_logo": LogoPreview,
            "brand_photos": Photo,
            "brand_videos": video,
            "brand_color": color,
            "brand_font": fonts,
            "unique_id": uniqueID
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                UpdateStatus()
                setIsLoader(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoader(false)
                message.error("There is some error please try again")
            })
    }

    const GenerateUniqueID = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_API}/unique/generate-id`)
            .then((res) => {
                setUniqueID(res.data.id)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    async function UpdateStatus() {
        var token;
        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, {
            status: 'completed'
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then((res) => {
                navigate("/");
            })
            .catch((err) => {
                console.log(err)
            })
    }


    const uploadStocket = async (e) => {

        if (e.target.files[0]['type'].split('/')[0] == "image") {
            setFileType('photo');
        }
        else {
            setFileType('video');
        }

        let filename;
        const file = e.target.files[0];
        if (file == undefined) {
            message.warning("Upload a Video");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'oolook-posting',
            Key: filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            if (e.target.files[0]['type'].split('/')[0] == "image") {
                setProgess(percentageProgress);
            }
            else {
                setvideoProgess(percentageProgress);
            }

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('Failed to upload file.');
            }
            else {
                if (e.target.files[0]['type'].split('/')[0] == "image") {
                    setPhoto(data.Location);
                    setPhotoPreview(data.Location)
                }
                else {
                    setVideo(data.Location);
                    setvideoPreview(data.Location);
                }


            }
        });

        try {
            await upload.promise().then(() => {
                message.success(`file Uploaded`);
            })

        } catch (error) {
            console.error('Upload failed', error);
        }


    }

    const BrandLogouploadStocket = async (e) => {

        if (e.target.files[0]['type'].split('/')[0] == "image") {
            setFileType('photo');
        }
        else {
            setFileType('video');
        }

        let filename;
        const file = e.target.files[0];
        if (file == undefined) {
            message.warning("Upload a File");
            return;
        }
        else {
            filename = (((file.name)).replace(/\s+/g, '')).replace('.', `${Date.now() + '.'}`);
        }


        const s3 = new AWS.S3({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const params = {
            Bucket: 'assets-libraries',
            Key: filename,
            Body: file,
        };

        const options = {
            partSize: 10 * 1024 * 1024, // Set the part size for multipart upload
            queueSize: 1 // Number of concurrent uploads
        };

        const upload = s3.upload(params, options);

        upload.on('httpUploadProgress', (progressData) => {
            const percentageProgress = Math.round((progressData.loaded / progressData.total) * 100);
            // setProgress(percentageProgress);
            setLogoProgess(percentageProgress)

        });

        s3.upload(params, (err, data) => {
            if (err) {
                console.error(err);
                alert('Failed to upload file.');
            }
            else {
                setLogoPeview(data.Location)
            }
        });

        try {
            await upload.promise().then(() => {
                message.success(`File Uploaded`);
            })

        } catch (error) {
            console.error('Upload failed', error);
        }


    }

    useEffect(() => {
        GenerateUniqueID();
    }, [])

    return (
        <div className='bg-images'>
            <div className='onbording_box'>

                <div className='steps_main_container'>
                    <Steps steps={2} />
                </div>

                <div className='form_section'>
                    <div className='onboard-line2'>Asset Library</div>


                    <div className='d-flex flex-column form_fields py-1'>

                        <div className='d-flex justify-content-between w-100'>
                            <label>
                                Brand Logo
                            </label>

                            <div>
                                {
                                    LogoProgess != 0
                                        ?
                                        <Progress type="circle" size={30} percent={LogoProgess} />
                                        :
                                        null
                                }
                            </div>
                        </div>

                        <div className='upload_Logo' onClick={() => { LogoRef.current.click() }}>
                            {
                                LogoPreview
                                    ?
                                    <img src={LogoPreview} style={{ width: '50%', borderRadius: '10px' }} />
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Logo
                                        </label>
                                    </>
                            }
                        </div>

                        <div>
                            <input type="file" onChange={(e) => { BrandLogouploadStocket(e); }} ref={LogoRef} accept="image/*" style={{ display: 'none' }} />
                        </div>
                    </div>






                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Color Palette
                        </label>

                        <div className='upload_Logo upload_color d-flex flex-row align-items-center justify-content-center'>
                            {
                                color != null
                                    ?
                                    <div className='d-flex flex-column px-3'>
                                        <div className='selected_color' style={{ backgroundColor: `${color}` }}>
                                            <div className='d-flex flex-column add_color_section'>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column add_color_section'>
                                            <label>
                                                {color}
                                            </label>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className='d-flex flex-column add_color_section align-items-center'>
                                <button className='add_color_btn' onClick={() => openBox()}>
                                    {
                                        open
                                            ?
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                            :
                                            <i className="fa fa-plus" aria-hidden="true"></i>
                                    }
                                </button>
                                <label>
                                    {
                                        open
                                            ?
                                            "Close"
                                            :
                                            "Add New"
                                    }
                                </label>
                            </div>

                            <div className='color_selection'>
                                {
                                    open && <HexColorPicker color={'#ffffff'} onChange={(e) => { setColor(e) }} />
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <label>
                            Fonts
                        </label>

                        <div className='upload_Logo d-flex flex-row align-items-center justify-content-center'>

                            <div className='mx-3'>
                                <p className='apply-font'>
                                    {
                                        fonts
                                    }
                                </p>
                            </div>


                            <div className='d-flex flex-column add_color_section align-items-center'>
                                {
                                    showFonts
                                        ?
                                        <FontPicker
                                            apiKey="AIzaSyAub6MjUwcD5fmBd8zZpCzLbHoDfnsMJW8"
                                            activeFontFamily={"Open Sans"}
                                            onChange={(nextFont) => {
                                                setFonts(nextFont.family)
                                                setShowfonts(false)
                                            }
                                            }
                                        />
                                        :
                                        <>
                                            <button className='add_color_btn' onClick={() => { setShowfonts(!showFonts) }}>
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                            <label>
                                                Add New
                                            </label>
                                        </>

                                }

                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <div className='d-flex justify-content-between w-100'>
                            <label>
                                Photos
                            </label>

                            <div>
                                {
                                    imgprogess != 0
                                        ?
                                        <Progress type="circle" size={30} percent={imgprogess} />
                                        :
                                        null
                                }
                            </div>
                        </div>


                        <div className='upload_Logo' onClick={() => { PhotoRef.current.click() }}>
                            {
                                Photo
                                    ?
                                    <img src={PhotoPreview} style={{ width: '50%', borderRadius: '10px' }} />
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Photos
                                        </label>
                                    </>
                            }

                        </div>



                        <div>
                            <input type="file" onChange={(e) => { uploadStocket(e); }} ref={PhotoRef} accept="image/*" style={{ display: 'none' }} />
                        </div>
                    </div>

                    <div className='d-flex flex-column form_fields py-1'>
                        <div className='d-flex justify-content-between w-100'>
                            <label>
                                Videos
                            </label>

                            <div>
                                {
                                    videoprogess != 0
                                        ?
                                        <Progress type="circle" size={30} percent={videoprogess} />
                                        :
                                        null
                                }
                            </div>
                        </div>

                        <div className='upload_Logo' onClick={() => VideoRef.current.click()}>
                            {
                                video
                                    ?
                                    <video src={videoPreview} width="400" controls >
                                    </video>
                                    :
                                    <>
                                        <i className="fas fa-cloud-upload"></i>
                                        <label>
                                            Upload Videos
                                        </label>
                                    </>
                            }
                        </div>


                        <div>
                            <input type="file" onChange={(e) => { uploadStocket(e); }} ref={VideoRef} accept="video/mp4,video/x-m4v,video/*" style={{ display: 'none' }} />
                        </div>
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between w-100 mt-3 px-5 py-3'>
                    <div className='back_btn_section'>
                        <button className='back_btn' onClick={() => { navigate('/Organization') }}>
                            Back
                        </button>
                    </div>

                    <div className='back_btn_section' onClick={() => { uploadAssets() }}>
                        <button className='next_btn'>
                            {
                                isLoader
                                    ?
                                    <Spin indicator={antIcon} />
                                    :
                                    "Next"

                            }
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )
}
