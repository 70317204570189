import React from 'react'

export default function Newpost() {
    return (
        <div className='add_post'>
            <div className='Create_post_head'>
                <label>New Post</label>
            </div>
            <div className='row'>
                {/* Form div */}
                <div className='col-6'>

                    <div className='create_main_section'>
                        <div className='pages'>
                            <div className='pages_box'>
                                <img src={require('../Assets/Frame.png')} />

                                <label>golds</label>

                                <i class="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>

                    </div>

                    <div className='form'>
                        <div className='check_box'>
                            <span>This is a story</span>
                            <label class="switch">
                                <input type="checkbox" />
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <div className='textarea_section'>
                            <textarea>

                            </textarea>

                        </div>

                        {/* Pintrest */}

                        <div className='pinterest_section'>
                            <div className='pinterest_head'>
                                <label>
                                    Pinterest Options
                                </label>

                                <i class="fa fa-angle-down"></i>
                            </div>

                            <div className='pinterest_input'>
                                <label>
                                    Pin Title
                                </label>
                                <input type="text" />

                                <label>
                                    Pin To
                                </label>
                                <input type="text" />

                                <label>
                                    Destination URL
                                </label>
                                <input type="text" />
                            </div>
                        </div>

                        {/* Instagram */}
                        <div className='pinterest_section'>
                            <div className='pinterest_head'>
                                <label>
                                    Instagram Options
                                </label>

                                <i class="fa fa-angle-down"></i>
                            </div>

                            <div className='pinterest_input'>
                                <label>
                                    Instagram First Comment
                                </label>
                                <input type="text" />

                                <label>
                                    Post Link <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                                </label>
                                <input type="text" />
                            </div>
                        </div>

                        {/* Google Business */}

                        <div className='pinterest_section'>
                            <div className='pinterest_head'>
                                <label>
                                    Google My Business Options
                                </label>

                                <i class="fa fa-angle-down"></i>
                            </div>

                            <div className='pinterest_input'>
                                <label>
                                    Post Type
                                </label>
                                <select className='dropdowns'>
                                    <option>“What’s New?” (basic psot)</option>
                                </select>

                                <label>
                                    Button Type (optional)
                                </label>
                                <select className='dropdowns'>
                                    <option>
                                        None
                                    </option>
                                </select>
                            </div>
                        </div>

                        {/* Post scheduled */}

                        <div className='pinterest_section'>
                            <div className='pinterest_head'>
                                <label>
                                    When to post
                                </label>

                                <div>
                                    Specific Days & Time
                                </div>

                                <i class="fa fa-angle-down"></i>
                            </div>

                            <div className='pinterest_input' style={{ padding: '10px' }}>
                                <table>
                                    <tr>
                                        <td>
                                            Date
                                        </td>

                                        <td>
                                            Time
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <input type="date" style={{ width: '90%' }} />
                                        </td>

                                        <td className='time_section'>
                                            <select className='dropdowns'>
                                                <option></option>
                                            </select>
                                            <label>
                                                :
                                            </label>
                                            <select className='dropdowns'>
                                                <option></option>
                                            </select>

                                            <select className='dropdowns'>
                                                <option></option>
                                            </select>
                                        </td>
                                    </tr>
                                </table>

                            </div>

                            <div className='add_more'>
                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                <label>
                                    Add more scheduled times
                                </label>
                            </div>
                        </div>

                        {/* Warring and error */}

                        <div className='pinterest_section'>
                            <div className='pinterest_head'>
                                <label>
                                    5 errors and 2 warnings
                                </label>

                                <i class="fa fa-angle-down"></i>
                            </div>

                            <div className='pinterest_input'>
                                <ul>
                                    <li>Image or video required when posting to Instagram Business. Save post as a draft to add media later.</li>
                                    <li>Video is required when posting to YouTube. Save post as a draft to add media later.</li>
                                    <li>Image is required when posting to Pinterest. Save post as a draft to add media later.</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Uploaded post */}
                <div className='col-6'>

                </div>
            </div>

        </div>
    )
}

