import React from 'react'

export default function Photographer() {
  return (
    <div>
        <div className='profile-connect'>
            <h2>Consultation & Shoot</h2>

            <button className='btn'>
                <i class="fa fa-close fa-xl"></i>
            </button>
        </div>
        <hr/>

        <div style={{ margin: '10px 20px' }}>
                <div className='campagin-head'>
                    Book a call with expert or a photographer
                </div>

                <div className='sub-head'>
                    You can schedule a photographer for your venture or book a strategy call
                </div>
            </div>

            <div className='campagin-section'>
                <label className='campagin-obejctive'>
                    Hello Yogesh
                </label>

                <div>
                    <button className='connect-btn campagin-btn'>
                        Photographer
                    </button>
                    
                    <button className='connect-btn campagin-btn'>
                        Consultation
                    </button>
                </div>

                <div className='photo-box'>
                    <button className='connect-btn campagin-btn1'>
                        05:30am
                    </button>
                    
                    <button className='connect-btn campagin-btn1'>
                        06:00am
                    </button>
                    
                    <button className='connect-btn campagin-btn1'>
                        06:30am
                    </button>
                </div>
            </div>
    </div>
  )
}
