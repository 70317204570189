import axios from 'axios'
import React, { useState } from 'react'

import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


export default function CreateCampagin() {

    const [Objective, setObjective] = useState('');

    const [Duration, setDuration] = useState('');

    const [Budget, setBudget] = useState('');

    const [post, setPost] = useState('');

    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#000' }} spin />;


    const [isLoader, setIsLoader] = useState(false);

    async function CreateCamp() {
        setIsLoader(true);
        let email;
        if (localStorage.getItem('Email')) {
            email = localStorage.getItem('Email');
        }
        else {
            email = sessionStorage.getItem('Email');
        }

        if (Objective == '') {
            message.error('Please Select Objective');
        }
        else if (Duration == '') {
            message.error('Please Enter Duration of Campaign')
        }
        else {
            await axios.post(`${process.env.REACT_APP_BACKEND_API}/campaign/create-campaign`, {
                "email_id": email,
                "objective": Objective,
                "duration": Duration,
                "budget": Budget,
                "post": "post"
            })
                .then((res) => {
                    setIsLoader(false);
                    message.success('Campaign Created');
                    console.log(res);
                })
                .catch((err) => {
                    setIsLoader(false);
                    console.log(err)
                })
        }

    }
    return (
        <div>
            <div className='profile-connect'>
                <h2>Create a campaign</h2>

                <button className='btn'>
                    <i class="fa fa-close fa-xl"></i>
                </button>
            </div>

            <hr />

            <div style={{ margin: '10px 20px' }}>
                <div className='campagin-head'>
                    Create a Brand Awareness or Lead Generation campaigns
                </div>

                <div className='sub-head'>
                    You can create paid campaigns based on suggestions or your objectives.
                </div>
            </div>

            <div className='campagin-section'>
                <label className='campagin-obejctive'>
                    Campaign Objective
                </label>

                <div className='d-flex'>
                    <button className={Objective == 'Brand Awareness' ? 'connect-btn campagin-btn objective_active' : 'connect-btn campagin-btn'} onClick={() => { setObjective('Brand Awareness') }}>
                        Brand Awareness
                    </button>
                    <button className={Objective == 'Lead Generation' ? 'connect-btn campagin-btn objective_active' : 'connect-btn campagin-btn'} onClick={() => { setObjective('Lead Generation') }}>
                        Lead Generation
                    </button>
                </div>

                <div style={{ display: "flex", flexDirection: "column", width: "30%", marginBottom: '10px' }}>
                    <label className='duration'>Duration</label>
                    <input type="date" className='duration-input' onChange={(e) => { setDuration(e.target.value) }} />
                </div>

                <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
                    <label className='duration'>Budget</label>
                    <input type="text" className='duration-input' placeholder='$ 55' onChange={(e) => { setBudget(e.target.value) }} />
                </div>

                <div>
                    <label className='select-post'>
                        Select Post
                    </label>


                    <div>
                        <label style={{ color: "rgb(119 124 124)" }}>
                            No Post Avaliable
                        </label>
                    </div>
                </div>

                <div className='campagin-create-btn'>
                    <button className='connect-btn create' disabled={isLoader} onClick={() => { CreateCamp() }}>
                        {
                            isLoader
                                ?
                                <Spin indicator={antIcon} />
                                :
                                "Create"
                        }
                    </button>
                </div>
            </div>


        </div>
    )
}
