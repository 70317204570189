import React from 'react'

export default function Activity() {
    return (
        <div className='row activity'>
            <h4>Your Latest Activity</h4>

            <div className='col-4 px-0 performance_section'>
                <div className='titles'>
                    <label>Performance Summary</label>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Impressions</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    5500
                                </label>
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>55.6%</label  >
                            </div>
                        </div>

                        <div className='col-6'>
                            <label>Engagements</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    23
                                </label>
                                <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                <label style={{ color: "#21A500" }}>
                                    5.7%
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Post Link Clicks</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    54
                                </label>
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                <label>
                                    5.2%
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='titles'>
                    <div className='row title_box1'>
                        <div className='col-6'>
                            <label>Engagement rate</label>
                            <div className='d-flex justify-content-between'>
                                <label>
                                    54
                                </label>
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                <label>
                                    5.2%
                                </label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='col-8'>
                <div className='summary'>
                    <div className='d-flex summary_header'>
                        <h4>Campaigns Summary</h4>

                        <div className='summary_inner_box'>
                            <button className='btn summary_create'>
                                Create
                            </button>

                            <label>Sort by</label>

                            <select>
                                <option>Highest Performance</option>
                                <option>Lowest Performance</option>
                            </select>
                        </div>
                    </div>

                    <div className='table_section'>
                        <table className='summary_table'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Campaign Name</th>
                                    <th>Type</th>
                                    <th>Budget</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        25/10/2023
                                    </td>

                                    <td>
                                        GGLGB700D7GGLGB700D7
                                    </td>

                                    <td>
                                        LG
                                    </td>

                                    <td>
                                        $ 500
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
