import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ContentLoader, { Facebook, Instagram } from 'react-content-loader'

export default function DummyPreview() {
    const MyInstagramLoader = () => <Instagram />
    return (
        <div>
            <div className='dummy_head'>
                <ContentLoader
                    speed={10}
                    width={300}
                    height={460}
                    viewBox="0 0 300 460"
                    backgroundColor="#dddada"
                    foregroundColor="#716f6f"
                >
                    <circle cx="31" cy="31" r="15" />
                    <rect x="58" y="18" rx="2" ry="2" width="100" height="10" />
                    <rect x="58" y="34" rx="2" ry="2" width="100" height="10" />
                    <rect x="25" y="60" rx="2" ry="2" width="350" height="400" />
                </ContentLoader>
            </div>
        </div>
    )
}
