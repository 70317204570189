import React from 'react'
import Header from './Header'
import DashScreen from './DashScreen'
import Activity from './Activity'

export default function Home() {
    return (
        <div>
            <Header />
            <DashScreen />
            <Activity />
        </div>
    )
}
