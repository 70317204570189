import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Months } from './MonthsString'
import Cookies from 'js-cookie';
import { Spin, message } from 'antd';
import Audiencegrowth from './Reports Pages/Audiencegrowth';

// import faker from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export default function ChartSection() {
    const [InstaGrowth, setInstagrwoth] = useState([]);
    const [FBAccessToken, setFBAccessToken] = useState('');
    const [FBMediaId, setMediaId] = useState('');
    const [subscriberGained, setSubscriberGained] = useState([]);
    const [YTsubscriber, setytSubscriber] = useState([]);
    const [LinkedinGrowth, setLinkedinGrowth] = useState([]);
    const [TwitterGrowth, setTwitterGrowth] = useState([]);
    const [FbIncData, setFBData] = useState([]);
    const [FbDecData, setFBDecData] = useState([]);

    const [FbGrowth, setFBGrowth] = useState([]);

    const [AudienceGrowths, setAudienceGrowths] = useState(null);

    const Sinceselector = useSelector((state) => {
        return state.SinceDates
    });

    const untilSelector = useSelector((state) => {
        return state.UntilDates
    })

    const Filter = useSelector((state) => {
        return state.Filter
    })

    // const NetAudienceGrowth = async (MediaId, Tokens) => {
    //     InstaGrowth.length = 0
    //     const since = new Date(Sinceselector[0] * 1000)
    //     const until = new Date(untilSelector[0] * 1000);


    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/follower-unfollower`, {
    //         accessToken: Tokens,
    //         mediaId: MediaId,
    //         since: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
    //         until: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
    //     })
    //         .then((res) => {
    //             (res.data).map((elem) => {
    //                 if (elem.data.data[0].total_value.breakdowns[0].results !== undefined) {
    //                     if (elem.data.data[0].total_value.breakdowns[0].results[1].value == 0) {
    //                         InstaGrowth.push(
    //                             {
    //                                 x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
    //                                 y: elem.data.data[0].total_value.breakdowns[0].results[0].value
    //                             },
    //                             {
    //                                 x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
    //                                 y: parseInt(elem.data.data[0].total_value.breakdowns[0].results[1].value)
    //                             }
    //                         )
    //                     }
    //                     else {
    //                         InstaGrowth.push(
    //                             {
    //                                 x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
    //                                 y: elem.data.data[0].total_value.breakdowns[0].results[0].value
    //                             },
    //                             {
    //                                 x: (elem.date).split("-")[1] + "/" + (elem.date).split("-")[2],
    //                                 y: parseInt("-" + elem.data.data[0].total_value.breakdowns[0].results[1].value)
    //                             }
    //                         )
    //                     }
    //                 }

    //             })
    //             setTimeout(() => {
    //                 setIsloader(true);

    //             }, 5000);

    //         })
    //         .catch((err) => {
    //             setIsloader(true);
    //             console.log(err);
    //         })
    // }

    // const facebookGrwoth = async (MediaId) => {
    //     var token;

    //     if (localStorage.getItem('user_Token')) {
    //         token = localStorage.getItem('user_Token');
    //     }
    //     else {
    //         token = sessionStorage.getItem('user_Token');
    //     }

    //     FbGrowth.length = 0
    //     setIsloader(false)
    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/metrics`, {
    //         pageId: MediaId,
    //         since: Sinceselector[0],
    //         until: untilSelector[0]
    //     }, {
    //         headers: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     })
    //         .then((res) => {
    //             // console.log("facebookdata",("0" + this.getDate()).slice(-2))
    //             const currentMonth = (new Date(Sinceselector[0] * 1000).getMonth()) + 1
    //             const currentDate = (new Date(Sinceselector[0] * 1000).getDate())
    //             FbGrowth.push({
    //                 x: ("0" + currentMonth).slice(-2) + "/" + ("0" + currentDate).slice(-2),
    //                 y: 0
    //             })
    //             for (var i = 0; i < (res.data.data[0].values).length; i++) {
    //                 if (res.data.data[2].values[i].value == 0) {
    //                     FbGrowth.push(
    //                         {
    //                             x: (res.data.data[0].values[i].end_time).split("T")[0].split("-")[1] + "/" + (res.data.data[0].values[i].end_time).split("T")[0].split("-")[2],
    //                             y: res.data.data[0].values[i].value
    //                         },
    //                         {
    //                             x: (res.data.data[2].values[i].end_time).split("T")[0].split("-")[1] + "/" + (res.data.data[2].values[i].end_time).split("T")[0].split("-")[2],
    //                             y: res.data.data[2].values[i].value
    //                         }
    //                     )
    //                 }
    //                 else {
    //                     FbGrowth.push(
    //                         {
    //                             x: (res.data.data[0].values[i].end_time).split("T")[0],
    //                             y: res.data.data[0].values[i].value
    //                         },
    //                         {
    //                             x: (res.data.data[2].values[i].end_time).split("T")[0],
    //                             y: parseInt("-" + res.data.data[2].values[i].value)
    //                         }
    //                     )
    //                 }
    //             }
    //             // AudienceGrowth(InstaID, Tokens)
    //             setIsloader(true)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const YoutubeGrowthGain = async () => {
    //     const since = new Date(Sinceselector[0] * 1000)
    //     const until = new Date(untilSelector[0] * 1000);

    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/youtube/metrics`, {
    //         accessToken: "ya29.a0AfB_byAMczXSDjpSvQLJNiut9JniPPtHr1zy9DzL3-If3wiT2Nvy43E2NZBeYrtoKZUJZFHgwcl9gyndV1XNIOmtmHa3SnQKDK0YiVQo3gIMb32biD3CwN0PYsGcHFIIHFtVs6_lT128gGwM007HZBagmx508M_l_eA4aCgYKAckSAQ8SFQHGX2MiBO2nWNxY16xZYE0C_rJbdQ0171",
    //         channelId: "UC70m1WS2lLY_gb5hcQnhfgA",
    //         startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
    //         lastDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
    //         metric: "subscribersGained"
    //     })
    //         .then((res) => {
    //             setSubscriberGained(res.data.rows)
    //             YoutubeGrowthLost()
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const YoutubeGrowthLost = async () => {
    //     setIsloader(false)
    //     const since = new Date(Sinceselector[0] * 1000)
    //     const until = new Date(untilSelector[0] * 1000);
    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/youtube/metrics`, {
    //         accessToken: "ya29.a0AfB_byAMczXSDjpSvQLJNiut9JniPPtHr1zy9DzL3-If3wiT2Nvy43E2NZBeYrtoKZUJZFHgwcl9gyndV1XNIOmtmHa3SnQKDK0YiVQo3gIMb32biD3CwN0PYsGcHFIIHFtVs6_lT128gGwM007HZBagmx508M_l_eA4aCgYKAckSAQ8SFQHGX2MiBO2nWNxY16xZYE0C_rJbdQ0171",
    //         channelId: "UC70m1WS2lLY_gb5hcQnhfgA",
    //         startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
    //         lastDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0"),
    //         metric: "subscribersLost"
    //     })
    //         .then((res) => {
    //             for (var i = 0; i < (res.data.rows).length; i++) {
    //                 if (res.data.rows[i][1] == 0) {
    //                     YTsubscriber.push({
    //                         x: (res.data.rows[i][0]).split("-")[1] + "/" + (res.data.rows[i][0]).split("-")[2],
    //                         y: subscriberGained[i][1]
    //                     },
    //                         {
    //                             x: (res.data.rows[i][0]).split("-")[1] + "/" + (res.data.rows[i][0]).split("-")[2],
    //                             y: res.data.rows[i][1]
    //                         }
    //                     )
    //                 }

    //                 setIsloader(true)
    //             }
    //         })
    //         .catch((err) => {
    //             setIsloader(true)
    //             console.log(err)
    //         })
    // }

    // const Linkedin = async () => {
    //     LinkedinGrowth.length = 0;

    //     const since = new Date(Sinceselector[0] * 1000)
    //     const until = new Date(untilSelector[0] * 1000);

    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/linkedin/analytics`, {
    //         accessToken: 'accessToken',
    //         postId: 'postId',
    //         startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
    //         endDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
    //     })
    //         .then((res) => {
    //             for (var i = 0; i < Object.keys(res.data[0]).length; i++) {
    //                 // res.data[0][(Object.keys(res.data[0])
    //                 if (Object.values(res.data[0])[i].analytics.follows == 0) {
    //                     LinkedinGrowth.push(
    //                         {
    //                             x: Object.keys(res.data[0])[i].split("-")[1] + "/" + Object.keys(res.data[0])[i].split("-")[2],
    //                             y: Object.values(res.data[0])[i].analytics.follows
    //                         },
    //                         {
    //                             x: Object.keys(res.data[0])[i].split("-")[1] + "/" + Object.keys(res.data[0])[i].split("-")[2],
    //                             y: parseInt(Object.values(res.data[0])[i].analytics.follows)
    //                         }
    //                     )
    //                 }
    //                 else {
    //                     LinkedinGrowth.push(
    //                         {
    //                             x: Object.keys(res.data[0])[i].split("-")[1] + "/" + Object.keys(res.data[0])[i].split("-")[2],
    //                             y: Object.values(res.data[0])[i].analytics.follows
    //                         },
    //                         {
    //                             x: Object.keys(res.data[0])[i].split("-")[1] + "/" + Object.keys(res.data[0])[i].split("-")[2],
    //                             y: parseInt("-" + Object.values(res.data[0])[i].analytics.follows)
    //                         }
    //                     )
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    // const Twitter = async (Token) => {
    //     TwitterGrowth.length = 0;

    //     const since = new Date(Sinceselector[0] * 1000)
    //     const until = new Date(untilSelector[0] * 1000);

    //     await axios.post(`${process.env.REACT_APP_BACKEND_API}/twitter/get-analytics`, {
    //         accessToken: Token,
    //         postId: 'postId',
    //         startDate: since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
    //         endDate: until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
    //     })
    //         .then((res) => {
    //             for (var i = 0; i < Object.keys(res.data).length; i++) {
    //                 // res.data[0][(Object.keys(res.data[0])
    //                 if (Object.values(res.data)[i].analytics.totalFollowers == 0) {
    //                     TwitterGrowth.push(
    //                         {
    //                             x: Object.keys(res.data)[i].split("-")[1] + "/" + Object.keys(res.data)[i].split("-")[2],
    //                             y: Object.values(res.data)[i].analytics.totalFollowers
    //                         },
    //                         {
    //                             x: Object.keys(res.data)[i].split("-")[1] + "/" + Object.keys(res.data)[i].split("-")[2],
    //                             y: parseInt(Object.values(res.data)[i].analytics.totalFollowers)
    //                         }
    //                     )
    //                 }
    //                 else {
    //                     TwitterGrowth.push(
    //                         {
    //                             x: Object.keys(res.data)[i].split("-")[1] + "/" + Object.keys(res.data)[i].split("-")[2],
    //                             y: Object.values(res.data)[i].analytics.totalFollowers
    //                         },
    //                         {
    //                             x: Object.keys(res.data)[i].split("-")[1] + "/" + Object.keys(res.data)[i].split("-")[2],
    //                             y: parseInt("-" + Object.values(res.data)[i].analytics.totalFollowers)
    //                         }
    //                     )
    //                 }
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }

    const GetAnalytics = async () => {
        FbIncData.length = 0;
        FbDecData.length = 0;
        const FilterProfile = [];
        var token;

        Filter[0].map((elem) => {
            if (elem.platform == "FACEBOOK") {
                FilterProfile.push(elem.platform_id)
            }
            else {
                FilterProfile.push(elem.platform_id)
            }
        })

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        const since = new Date(Sinceselector[0] * 1000)
        const until = new Date(untilSelector[0] * 1000);

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/group-analytics`, {
            "groupName": localStorage.getItem("oolook_default_group"),
            "selectedProfiles": FilterProfile,
            "since": since.getFullYear() + "-" + (since.getMonth() + 1).toString().padStart(2, "0") + "-" + since.getDate().toString().padStart(2, "0"),
            "until": until.getFullYear() + "-" + (until.getMonth() + 1).toString().padStart(2, "0") + "-" + until.getDate().toString().padStart(2, "0")
        }, {
            headers: {
                'Authorization': `Barear ${token}`
            }
        })
            .then((res) => {
                if (res.data.analytics.FACEBOOK != undefined) {
                    (res.data.analytics.FACEBOOK).map((elem, index) => {
                        // FbData.push([...elem.data[0].values,...elem.data[2].values]);
                        if (elem.data[2].name == "page_daily_follows") {
                            FbIncData.push(...elem.data[2].values)
                        }

                        if (elem.data[4].name = "page_daily_unfollows") {
                            FbDecData.push(...elem.data[4].values)
                        }
                    })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }



    useEffect(() => {
        if (Filter.length != 0) { 
            GetAnalytics()
        }
    }, [Sinceselector, untilSelector, Filter])

    return (
        <>
            <div className='chart_section'>
                <div className='chart_heading d-flex flex-column px-3 py-3'>
                    <label className='label_head'>
                        Audience Growth
                    </label>

                    <label className='label_sub_head'>
                        See how your audience grew during the reporting period.
                    </label>
                </div>

                <Audiencegrowth FBData={FbIncData} Fbunfollow={FbDecData} />
            </div>
        </>
    );
}
