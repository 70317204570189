import React, { useEffect, useState } from 'react'
import Onboard from '../Assets/onbording_icon1.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spin, message } from 'antd';
import Steps from '../Steps';

export default function Onboarding() {

  const [Ans1, setAns1] = useState('');
  const [Ans2, setAns2] = useState('');
  const [Ans3, setAns3] = useState('');

  const [steps, setSteps] = useState(2);


  const [isLoader, setisLoader] = useState(false);

  const [Ques, setQues] = useState(1);

  const navigate = useNavigate();

  const Question1 = () => {
    return (
      <>
        <div className='onboard-line2'>What best describes you?</div>

        <div className='box-div'>
          <button className={Ans1 == "Content Creator or Influencer" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns1('Content Creator or Influencer'); setQues(2); setSteps(2.3) }}>
            <img src={require('../Assets/onbording_icon1.png')} className='onboard-img' />
            <div className='content'>Content Creator or Influencer</div>
          </button>

          <button className={Ans1 == "Marketing Agency" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns1('Marketing Agency'); setQues(2); setSteps(2.3) }}>
            <img src={require('../Assets/Marketing_Agency.png')} className='onboard-img' />
            <div className='content'>Marketing Agency</div>
          </button>
        </div>

        <div className='box-div'>
          <button className={Ans1 == "SMB Owner" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns1('SMB Owner'); setQues(2); setSteps(2.3) }}>
            <img src={require('../Assets/SMB_owner.png')} className='onboard-img' />
            <div className='content'>SMB Owner</div>
          </button>

          <button className={Ans1 == "Social Media Manager" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns1('Social Media Manager'); setQues(2) }}>
            <img src={require('../Assets/Social_media.png')} className='onboard-img' />
            <div className='content'>Social Media Manager</div>
          </button>
        </div>
      </>
    )
  }

  const SocialMedia = () => {
    return (
      <div className='text-center'>
        <div className='onboard-line2'> Select your Industry</div>

        <div className='text-center w-100'>
          <button className={Ans2 == "Health & Fitness" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns2('Health & Fitness'); setQues(3) }}>
            <img src={require('../Assets/Yoga.png')} className='onboard-img' />
            <div className='content'>Health & Fitness</div>
          </button>

          <button className={Ans2 == "Beauty & Skincare" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns2('Beauty & Skincare'); setQues(3) }}>
            <img src={require('../Assets/Skin.png')} className='onboard-img' />
            <div className='content'>Beauty & Skincare</div>
          </button>

          <button className={Ans2 == "Food & Beverages" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns2('Food & Beverages'); setQues(3) }}>
            <img src={require('../Assets/Food.png')} className='onboard-img' />
            <div className='content'>Food & Beverages</div>
          </button>
        </div>

        <div className='mt-5'>
          <label>
            Others:
          </label>

          <input type='text' className='Other_field' onChange={(e) => { setAns2(e.target.value) }} placeholder='Type Here' />
        </div>

        <div className='back_btn_section px-5' onClick={() => { setQues(1) }}>
          <button className='back_btn'>
            Back
          </button>
        </div>
      </div>
    )
  }

  const Question3 = () => {
    return (
      <>
        <div className='onboard-line2'>Select Your Primary Goals</div>

        <div className='text-center w-100'>
          <button className={Ans3 == "Brand Awarness" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns3('Brand Awarness'); }}>
            <img src={require('../Assets/Brand_awarness.png')} className='onboard-img' />
            <div className='content'>Brand Awarness</div>
          </button>

          <button className={Ans3 == "Lead Generation" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns3('Lead Generation'); }}>
            <img src={require('../Assets/Lead_generation.png')} className='onboard-img' />
            <div className='content'>Lead Generation</div>
          </button>

          <button className={Ans3 == "Customer Feedback" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns3('Customer Feedback'); }}>
            <img src={require('../Assets/Customer_feedback.png')} className='onboard-img' />
            <div className='content'>Customer Feedback</div>
          </button>

          <button className={Ans3 == "Promote Content" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns3('Promote Content'); }}>
            <img src={require('../Assets/Promote_content.png')} className='onboard-img' />
            <div className='content'>Promote Content</div>
          </button>

          <button className={Ans3 == "Target Audience" ? 'btn box1 active_box' : 'btn box1'} onClick={() => { setAns3('Target Audience'); }}>
            <img src={require('../Assets/Target_audience.png')} className='onboard-img' />
            <div className='content'>Target Audience</div>
          </button>
        </div>

        <div className='d-flex align-items-center justify-content-between w-100 mt-5 px-5'>
          <div className='back_btn_section' onClick={() => { setQues(2) }}>
            <button className='back_btn'>
              Back
            </button>
          </div>

          <div className='back_btn_section' onClick={() => { OnboardingAPI() }}>
            <button className='next_btn'>
              Next
            </button>
          </div>
        </div>
      </>
    )
  }

  function OnboardingAPI() {
    setisLoader(true)
    var token;
    if (localStorage.getItem('user_Token')) {
      token = localStorage.getItem('user_Token');
    }
    else {
      token = sessionStorage.getItem('user_Token');
    }
    
    axios.post(`${process.env.REACT_APP_BACKEND_API}/onboarding/add-onboarding1`, {
      describes: Ans1,
      industry: Ans2,
      top_goal: Ans3
    },{
      headers:{
        "Authorization": `Bearer ${token}`
      }
    })
      .then((res) => {
        setisLoader(false)
        UpdateStatus()
      })
      .catch((err) => {
        console.log(err);
        setisLoader(false)
        message.error('Some Error Please Try Again Later')
      })
  }

  async function UpdateStatus() {
    var token;
    if (localStorage.getItem('user_Token')) {
      token = localStorage.getItem('user_Token');
    }
    else {
      token = sessionStorage.getItem('user_Token');
    }
    await axios.patch(`${process.env.REACT_APP_BACKEND_API}/user/update-status`, {
      status: 'onboarding completed'
    },{
      headers:{
        'Authorization':`Barear ${token}`,
      }
    })
      .then((res) => {
        console.log(res)
        navigate('/Organization');
      })
      .catch((err) => {
        console.log(err)
      })
  }


  return (
    <div className='bg-images'>
      <div className='onbording_box'>
        {
          isLoader
            ?
            <div id="overlay">
              <div className='text-center loader_center'>
                <Spin size="large" />
              </div>
            </div>
            :
            null
        }
        <div className='steps_main_container'>
          <Steps steps={steps} />
        </div>
        {
          Ques === 1
            ?
            Question1()
            :
            Ques === 2
              ?
              SocialMedia()
              :
              Question3()
        }
      </div>

    </div>
  )
}
