import React, { useEffect, useState } from 'react'
import './steps.css'

export default function Steps(steps) {
    const [activeSteps, setActivestep] = useState(1);

    // useEffect(()=>{
    //     console.log(steps.steps)
    // },[])

    return (
        <div className='steps-container w-75'>

            <div className="progress-container">
                <div className="progress" id="progress"></div>
                <div className={steps.steps == 1 || 2 ? "text-wrap actives" : "text-wrap"}>
                    <div className="circle">1</div>
                    <p className="text">Create Account</p>
                </div>

                <div className={steps.steps==2?'progess_bar':'progess_bar_inactive'}></div>

                <div className={steps.steps == 2? "text-wrap actives" : "text-wrap"}>
                    <div className="circle">2</div>
                    <p className="text">Personal Info</p>
                </div>
            </div>

        </div>
    )
}
